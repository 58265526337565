<template>
    <background>

            <div class="flex-layout">

                <view-header :title="lang('menu.sos')"></view-header>
                <div class="view p-lr page-eol">
                    <resource-placeholder :resource="'astraServices'">
                        <div class="content">

                            <div class="page-intro">

                                <h1>{{ lang('views.astra-services.title') }}</h1>
                                <p>{{ lang('views.astra-services.desc') }}</p>

                            </div>

                            <post-menu :posts="posts" category="astra-services"></post-menu>

                        </div>
                    </resource-placeholder>
                </div>

            </div>

    </background>
</template>

<script>


import settings from "../settings";

export default {

    computed: {

        posts() {

            let resource = this.getResource('astraServices');

            return resource.data.attributes.posts.posts.data

        },

    },

}


</script>

