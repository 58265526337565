<template>
    <div class="level-stats">

        <div class="left">
            <div class="count-cont">
                <div class="shield-progress">
                    <img class='placeholder' src="../assets/img/shield.png">
                    <div class="bar" :style="{height: $store.getters.scoreProgress + '%'}">
                        <img src="../assets/img/shield.png">
                    </div>
                </div>
                <badge :color="$store.getters.scoreColor"></badge>
                <div class="counter">
                    <div class="score-stat">
                        <div class="current">{{ $store.getters.currentPoints }}</div>
                        <div class="total">/{{ $store.getters.totalPoints }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="right">
            <label for="">{{ lang('level-stats.current-level') }}</label>
            <div class="level">{{ lang('level-stats.level.' + $store.getters.currentLevel + '.title') }}</div>
            <p>
                {{ lang('level-stats.level.' + $store.getters.currentLevel + '.desc') }}
            </p>
        </div>

    </div>
</template>

<script>

export default {

    data() {

    },

    computed: {

    }

}



</script>

<style scoped lang="scss">

@import "../scss/includes.scss";

.level-stats {
    display: flex;
    align-items: flex-start;
    padding: 0 10px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 7px;
    // border: 1px solid rgba(255,255,255,0.1);
    padding: 20px;
}

.count-cont{

    position: relative;

    .badge {
        position: absolute;
        width: 25px;
        height: 25px;
        top: 0;
        right: -10px;
    }

    .shield-progress{

        position: relative;

        $height: 80px;
        
        img {
            height: $height;
            display: block;
        }

        .placeholder{
            opacity: 0.2;
        }

        .bar{
            position: absolute;
            bottom: 0;
            left: 0;
            height: 100%;
            width: 100%;
            overflow: hidden;
            height: 10%;

            img{
                height: $height;
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }

        
        


    }



}

.right {

    padding-left: 25px;

    label {
        margin-top: -4px;
        display: block;
        font-size: 0.75em;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 0.5px;
        color: color(2, "yellow");
        font-weight: 500;
    }

    .level{
        margin-top: -3px;
        font-weight: 500;
    }

    p {
        font-size: 0.80em;
        line-height: 1.35em;
        margin-top: 5px;
        opacity: 0.7;
    }

}


.counter{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    

}




</style>
