export default {

    "views.language.title": "भाषा चुनें",
    "views.language.confirm": "पुष्टि करें",

    // Intro परिचय
    "views.intro.1.title": "आपका स्वागत है",
    "views.intro.2.title": "मानव तस्करी के विरुद्ध लड़ाई",
    "views.intro.3.title": "मानव तस्करी के बारे में ज़्यादा जानें",
    "views.intro.4.title": "इस सर्वेक्षण को पूरा करें",
    "views.intro.1.description": "एस्ट्रा एक गैर-सरकारी संगठन है जो मानव तस्करी और शोषण के सभी रूपों को खत्म करने के लिए समर्पित है",
    "views.intro.2.description": "मानव तस्करी के विरुद्ध लड़ाई व्यापक होनी चाहिए, जिसमें रोकथाम, पीड़ितों के लिए सुरक्षा और सहायता के साथ-साथ अपराधियों के विरुद्ध मुकदमा चलाने के क्षेत्र में कार्रवाई शामिल है।",
    "views.intro.3.description": "यह एप्लीकेशन आपको सर्बिया और विदेशों में मानव तस्करी और रोजगार की स्थिति, खुद को कैसे सुरक्षित रखें, साथ ही, देश और विदेश में उपयोगी संपर्कों के बारे में बुनियादी जानकारी देगा।",
    "views.intro.4.description": "जितना संभव हो सके, उतनी कुशलता से काम करने के लिए, एस्ट्रा एक स्वैच्छिक सर्वेक्षण कर रहा है। मानव तस्करी से निपटने में हमारे साथ भाग लें",
    "views.intro.button.next": "अगला",
    "views.intro.button.survey": "सर्वेक्षण",
    "views.intro.button.survey.skip": "छोड़ें",
    "views.intro.button.start.survey": "सर्वेक्षण शुरू करें",
    "views.intro.button.back.survey": "वापस जाएं",
    "views.intro.button.start.game": "प्रश्नोत्तरी शुरू करें",

    // SOS एसओएस 
    "views.sos.description": "अगर आपको अतिरिक्त निवारक जानकारी की आवश्यकता है, दुविधा है, अपने परिवेश में मानव तस्करी के किसी रूप को पहचानते हैं या सहायता और समर्थन की आवश्यकता है, तो आप हमसे संपर्क कर सकते हैं",
    "views.sos.report-problem.title": "किसी समस्या को रिपोर्ट करें",
    "views.sos.report-problem.message": " इस फॉर्म के माध्यम से हमसे संपर्क करें",
    "views.sos.phone.1.message": "24/7 उपलब्ध",
    "views.sos.phone.2.message": "24/7 उपलब्ध (सर्बिया के क्षेत्र से निःशुल्क)",
    "views.sos.fb.title": "फेसबुक/मैसेंजर",
    "views.sos.fb.message": "आधिकारिक फेसबुक पेज",
    "views.sos.mail.title": "ई-मेल",
    "views.sos.mail.message": "ई-मेल (sos@astra.rs) के माध्यम से हमसे संपर्क करें",

    //Country residence देश का निवास
    "views.eol.country.title": "देश चुनें",
    "views.eol.country.description": "अगर आप विदेश में नौकरी की तलाश कर रहे हैं, तो हमने आपके लिए कुछ यूरोपीय देशों में रोजगार की स्थितियों के बारे में उपयोगी जानकारी तैयार की है।",

    //Exploitation शोषण
    "views.exploitation.description": "रोजगार के बारे में निर्णय लेने से पहले जानकारी इकठ्ठा करें और अपने अधिकारों के बारे में जानें।",
    "views.exploitation.general.title": "उपयोगी जानकारी",
    "views.exploitation.general.desc": "श्रमिक शोषण के बारे में उपयोगी जानकारी",
    "views.exploitation.domestic.title": "अगर आप विदेश में हैं",
    "views.exploitation.domestic.desc": "सर्बियाई नागरिकों के लिए",
    "views.exploitation.foreign.title": "अगर आप सर्बिया में विदेशी हैं",
    "views.exploitation.foreign.desc": "सर्बिया में विदेशी नागरिकों के लिए",

    // Home होम
    "views.home.quiz.title": "प्रश्नोत्तरी",
    "views.home.quiz.description": "प्रश्नोत्तरी खेल के माध्यम से तस्करी विरोधी के बारे में अधिक जानें",
    "views.home.quiz.play": "खेलें",
    "views.home.news-activities-title": "समाचार एवं गतिविधियाँ",
    "views.home.more": "और खबरें",

    // Astra services एस्ट्रा की सेवाएं
    "views.astra-services.title": "एस्ट्रा द्वारा प्रदान की जाने वाली सेवाएँ",
    "views.astra-services.desc-short": "सेवाओं के बारे में अधिक जानें",
    "views.astra-services.desc": "इस पृष्ठ पर आप एस्ट्रा द्वारा प्रदान की जाने वाली सेवाओं के बारे में जानकारी पा सकते हैं",

    // Quiz प्रश्नोत्तरी
    "views.quiz.title": "प्रश्नोत्तरी",
    "views.quiz.description": "एक इंटरैक्टिव प्रश्नोत्तरी के माध्यम से मानव तस्करी के बारे में अधिक जानें। प्रश्नोत्तरी में से एक चुनें।",
    "views.quiz.play": "प्रश्नोत्तरी शुरू करें",
    'views.quiz.stats.question': 'प्रश्न',
    'views.quiz.stats.score': 'अंक',
    'views.quiz.stats.time': 'समय',
    'views.quiz.end.title': 'खेल का अंत',
    'views.quiz.end.description': 'प्रश्नोत्तरी में हिस्सा लेने के लिए धन्यवाद।',
    'views.quiz.end.correct-answers': 'सही उत्तर',
    'views.quiz.end.incorrect-answers': 'गलत उत्तर',
    'views.quiz.end.question-summary': 'प्रश्न',
    'views.quiz.timeout': 'आपका समय समाप्त हो गया है',
    'views.quiz.action.next': 'आगे',
    'views.quiz.action.ok': 'ठीक है',
    'views.quiz.action.skip': 'छोड़ें',
    'views.quiz.action.confirm': 'पुष्टि करें',
    'views.quiz.info': 'जानकारी',
    'views.quiz.right': 'आप सही हैं।',
    'views.quiz.wrong': 'दुर्भाग्य से, आपसे गलती हो गई।',

    
    'views.back.exit.title': 'क्या आपको यकीन है?',
    'views.back.exit.desc': 'क्या आप वाकई बाहर निकलना चाहते हैं? आपका वास्तविक डेटा (स्कोर आदि) खो जाएगा',

    // Anti trafficking तस्कर रोधी
    "views.anti-trafficking.title": "तस्कर रोधी",

    // Report problem
    "views.report-problem.intro.description": "अगर आप असुरक्षित महसूस करते हैं, भयभीत हैं या अपना व्यक्तिगत डेटा हमारे साथ साझा नहीं करना चाहते हैं, तो आप हमें गुमनाम रूप से भी लिख सकते हैं।",
    "views.report-problem.form.d.description": "प्रिय, आपके विश्वास के लिए धन्यवाद। कृपया रिपोर्ट में अपनी संपर्क जानकारी छोड़ें और जिस स्थिति की आप रिपोर्ट कर रहे हैं उसका यथासंभव विस्तार से वर्णन करें। हमसे जल्द से जल्द संपर्क करने की अपेक्षा करें।",
    "views.report-problem.form.a.description": "प्रिय, आपके विश्वास के लिए धन्यवाद। गुमनाम रिपोर्ट की विशिष्टता को ध्यान में रखते हुए, हम आपके द्वारा हमारे साथ साझा की गई जानकारी की मात्रा के संबंध में कार्रवाई करने में सक्षम होंगे। कृपया समस्या का यथासंभव विस्तार से वर्णन करें और अगर संभव हो तो उस व्यक्ति से संपर्क करने का तरीका बताएं जो मानव तस्करी का शिकार बनने के संभावित जोखिम में है।",
    "views.report-problem.action.data": "निजी डेटा के साथ रिपोर्ट करें",
    "views.report-problem.action.anonymous": "गुमनाम रिपोर्ट",
    "views.report-problem.end.placeholder.title": "धन्यवाद!",
    "views.report-problem.end.placeholder.message": "आपका संदेश भेज दिया गया है।",
    "views.report-problem.end.placeholder.back": "वापस",
    'views.report-problem.form.label.name': "पहला और आखिरी नाम",
    'views.report-problem.form.label.phone': "फोन संपर्क",
    'views.report-problem.form.label.mail': "ई-मेल",
    'views.report-problem.form.label.title': "शीर्षक",
    'views.report-problem.form.label.message': "संदेश",
    'views.report-problem.form.submit': "जमा करें",
    'views.report-problem.validation.title': 'कृपया फ़ील्ड सत्यापित करें',
    'views.report-problem.validation.desc': 'कुछ फ़ील्ड खाली हैं या सही फ़ॉर्मेट में नहीं हैं. कृपया सभी फ़ील्ड चेक करें.',
    'views.report-problem.client.title': 'क्लाइंट त्रुटि',
    'views.report-problem.client.desc': 'कोई नेटवर्क नहीं',
    'views.report-problem.server.title': 'सर्वर त्रुटि',
    'views.report-problem.server.desc': 'एक अनपेक्षित त्रुटि उत्पन्न हुई',

    // Survey सर्वेक्षण
    "views.survey.title": "सर्वेक्षण",
    "views.survey.description": "श्रम शोषण की घटना के बारे में बेहतर जानकारी प्राप्त करने तथा हमारी भावी गतिविधियों की योजना बनाने के लिए, आपका ज्ञान और अनुभव, जिसे आप एक गुमनाम सर्वेक्षण में कुछ प्रश्नों के माध्यम से हमारे साथ साझा कर सकते हैं, हमारे लिए बहुत महत्वपूर्ण होगा।",
    "views.survey.start": "सर्वेक्षण शुरू करें",
    'views.survey.exit.title': 'क्या आपको यकीन है?',
    'views.survey.exit.desc': 'क्या आप वाकई सर्वेक्षण से बाहर निकलना चाहते हैं?',
    "views.survey-welcome.title": "आपका स्वागत है",
    "views.survey-welcome.placeholder.title": "सर्वेक्षण शुरू करें",
    "views.survey.placeholder.content": "मेकेनास सिट अमेट सोलिसिटुडिन डोलर, क्विस फ्रिंजिला टॉर्टर, क्विस फ्रिंजिला टॉर्टर।",
    "views.survey.placeholder.button.content": "जारी रखें",
    "views.survey.button.next": "अगला",
    "views.survey.button.previous": "वापस",
    "views.survey.end-screen.title": "धन्यवाद!",
    "views.survey.end-screen.content": "इस डेटा का उपयोग श्रम शोषण से निपटने के लिए गतिविधियों की योजना बनाने और मानव तस्करी के (संभावित) पीड़ितों को प्रदान की जाने वाली सेवाओं के विकास के लिए किया जाएगा।",
    "views.survey.end-screen.button.content": "वापस",

    // Post पोस्ट 
    'views.post.title' : 'समाचार',

    // Resource placeholder रिसोर्स प्लेसहोल्डर
    'placeholder.network.title': 'नेटवर्क त्रुटि',
    'placeholder.network.message': 'कोई डेटा उपलब्ध नहीं है। कृपया अपना इंटरनेट कनेक्शन जांचें और पुनः प्रयास करें।',
    'placeholder.network.action': 'पुनः प्रयास करें',

    // Menu मेनू 
    "menu.home": "होम",
    "menu.anti-trafficking": "तस्कर रोधी",
    "menu.eol": "श्रम का शोषण",
    "menu.sos": "एसओएस",
    "menu.quiz": "प्रश्नोत्तरी",
    "menu.survey": "सर्वेक्षण",
    "menu.report-problem": "समस्या रिपोर्ट करें",
    "menu.exit": "बाहर जाएं",

    // Misc विविध
    "cancel": "रद्द करें",
    "confirm": "पुष्टि करें",
    "ok": "ठीक है",
    "yes": "हाँ",
    "no": "नहीं",
    "translate": "अनुवाद करें",
    "available-translations": "उपलब्ध अनुवाद",
    "auto-translate": "स्वतः अनुवाद",
    "share-news": "इस समाचार को साझा करें",
    "share-app": "इस ऐप को साझा करें",
    "share.try-astra-app": "एस्ट्रा ऐप आज़माएं",
    "music": "संगीत",
    "privacy-policy": "गोपनीयता नीति",

    "dialog.survey.title": "नया सर्वेक्षण उपलब्ध है!",
    "dialog.survey.message": "ऐप में नया सर्वेक्षण उपलब्ध है। इस मिशन में हमारी मदद करें। सर्वेक्षण में भाग लें!",
    "dialog.survey.later": "बाद में",
    "dialog.survey.go": "मुझे सर्वेक्षण के लिए ले चलें!",

    
    "dialog.share-app.title" : "एस्ट्रा ऐप को अन्य लोगों के साथ साझा करें",

    // Level Stats
    "level-stats.current-level": "आपका मौजूदा स्तर",

    "level-stats.level.1.title": "आशा की चिंगारी",
    "level-stats.level.1.desc": "आप वर्तमान में 'आशा की चिंगारी' हैं! हमारे शैक्षिक मंच के माध्यम से मानव तस्करी के बारे में जागरूकता बढ़ाएं।",
    "level-stats.level.2.title": "न्याय यात्री",
    "level-stats.level.2.desc": "आपका समर्थन महत्वपूर्ण है! आपने अपनी सहभागिता और जागरूकता बढ़ाने के माध्यम से योगदान दिया है। प्रगति जारी रखें!",
    "level-stats.level.3.title": "आज़ादी की आवाज़",
    "level-stats.level.3.desc": "मौजूदा 'आज़ादी की आवाज़' के रूप में, मानव तस्करी के खिलाफ लड़ाई के प्रति आपका समर्पण दूसरों को प्रेरित करता है। बदलाव के लिए मानक स्थापित करते रहें!",

    "level-stats.upgraded.title": "बधाई हो!",
    "level-stats.upgraded.desc": "आप अगले स्तर पर पहुंच गए हैं!",

    "level-stats.more": "स्तरों के बारे में अधिक जानकारी",

    // Levels स्तर
    "views.levels.title": "स्तर",
    "views.levels.page-title": "मानव तस्करी के विरुद्ध सामूहिक लड़ाई में शामिल हों और योगदान दें",
    "views.levels.page-desc": "मानव तस्करी के खिलाफ एक सच्चे योद्धा बनने के लिए कार्य पूरे करें। जानकारी प्राप्त करें, प्रश्नोत्तरी और सर्वेक्षणों में भाग लें, और अपने दोस्तों के साथ ऐप साझा करें।",

    "views.levels.quiz.title": "प्रश्नोत्तरी खेल",
    "views.levels.quiz.desc": "प्रश्नोत्तरी में भाग लें, मानव तस्करी के बारे में अपने ज्ञान को परखें और आप [एन] अंक अर्जित कर सकते हैं।",

    "views.levels.share.title": "ऐप साझा करें",
    "views.levels.share.desc": "ऐप को अपने दोस्तों के साथ साझा करें और [n] अंक अर्जित करें।",

    "views.levels.survey.title": "सर्वेक्षण",
    "views.levels.survey.desc": "सर्वेक्षण पूरा करें और [n] अंक अर्जित करें।",

}
