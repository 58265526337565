export default {

    
  "views.language.title": "选择语言",
  "views.language.confirm": "确认",

  // Intro
  "views.intro.1.title": "欢迎",
  "views.intro.2.title": "打击人口贩卖",
  "views.intro.3.title": "了解更多关于人口贩卖的信息",
  "views.intro.4.title": "填写调查问卷",
  "views.intro.1.description": "ASTRA 是一个致力于消除所有形式的人口贩卖和剥削的非政府组织",
  "views.intro.2.description": "打击人口贩卖需要全面的行动，包括预防、保护和支持受害者，以及起诉犯罪者。",
  "views.intro.3.description": "本应用程序将为您提供有关人口贩卖以及塞尔维亚和国外就业条件的基本信息，如何保护自己，并提供国内外有用的联系方式。",
  "views.intro.4.description": "为了更有效地开展工作，ASTRA 进行了一项自愿调查。请与我们一起参与打击人口贩卖。",
  "views.intro.button.next": "下一步",
  "views.intro.button.survey": "调查问卷",
  "views.intro.button.survey.skip": "跳过",
  "views.intro.button.start.survey": "开始调查",
  "views.intro.button.back.survey": "返回",
  "views.intro.button.start.game": "开始游戏",

  // SOS
  "views.sos.description": "如果您需要额外的预防信息、有疑问、察觉到身边存在某种形式的人口贩卖，或者需要帮助和支持，您可以联系我们",
  "views.sos.report-problem.title": "报告问题",
  "views.sos.report-problem.message": "通过表格联系我们",
  "views.sos.phone.1.message": "24/7 全天候服务",
  "views.sos.phone.2.message": "24/7 全天候服务（塞尔维亚境内免费）",
  "views.sos.fb.title": "Facebook/Messenger",
  "views.sos.fb.message": "官方 Facebook 页面",
  "views.sos.mail.title": "电子邮件",
  "views.sos.mail.message": "通过电子邮件联系我们（sos@astra.rs）",

  // Country residence
  "views.eol.country.title": "选择国家",
  "views.eol.country.description": "如果您打算在国外寻找工作，我们为您准备了有关一些欧洲国家就业条件的有用信息。",

  // Exploitation
  "views.exploitation.description": "在做出就业决定之前，收集信息并了解您的权利。",
  "views.exploitation.general.title": "有用信息",
  "views.exploitation.general.desc": "有关劳动剥削的有用信息",
  "views.exploitation.domestic.title": "如果您在国外",
  "views.exploitation.domestic.desc": "针对塞尔维亚公民",
  "views.exploitation.foreign.title": "如果您是塞尔维亚的外国人",
  "views.exploitation.foreign.desc": "针对在塞尔维亚的外国公民",

  // Home
  "views.home.quiz.title": "测验",
  "views.home.quiz.description": "通过测验了解更多有关人口贩卖的知识",
  "views.home.quiz.play": "开始测验",
  "views.home.news-activities-title": "新闻与活动",
  "views.home.more": "更多...",

  // Astra services
  "views.astra-services.title": "Astra 提供的服务",
  "views.astra-services.desc-short": "了解更多服务信息",
  "views.astra-services.desc": "在此页面上，您可以找到 Astra 提供的服务信息",

    // Quiz
    "views.quiz.title": "测验",
    "views.quiz.description": "通过互动测验了解更多有关人口贩卖的信息。选择一个测验开始。",
    "views.quiz.play": "开始测验",
    "views.quiz.stats.question": "问题",
    "views.quiz.stats.score": "分数",
    "views.quiz.stats.time": "时间",
    "views.quiz.end.title": "游戏结束",
    "views.quiz.end.description": "感谢您参与测验。",
    "views.quiz.end.correct-answers": "正确答案",
    "views.quiz.end.incorrect-answers": "错误答案",
    "views.quiz.end.question-summary": "问题总结",
    "views.quiz.timeout": "您的时间已到",
    "views.quiz.action.next": "下一步",
    "views.quiz.action.ok": "确定",
    "views.quiz.action.skip": "跳过",
    "views.quiz.action.confirm": "确认",
    "views.quiz.info": "信息",
    "views.quiz.right": "您是对的。",
    "views.quiz.wrong": "很遗憾，您答错了。",

    "views.back.exit.title": "您确定吗？",
    "views.back.exit.desc": "您确定要离开此页面吗？您的数据将会丢失（测验、调查）。",
    // Anti trafficking
    "views.anti-trafficking.title": "打击人口贩卖",

    // Report problem
    "views.report-problem.intro.description": "如果您感到不安全、害怕或不愿透露您的个人信息，您可以匿名联系我们。",
    "views.report-problem.form.d.description": "尊敬的用户，感谢您的信任。请在报告中留下您的联系方式，并尽可能详细地描述您所报告的情况。我们会尽快联系您。",
    "views.report-problem.form.a.description": "尊敬的用户，感谢您的信任。鉴于匿名报告的特殊性，我们将根据您提供的信息采取相应的行动。请尽可能详细地描述问题，并尽可能提供与可能面临成为人口贩卖受害者风险的人员的联系方式。",
    "views.report-problem.action.data": "提交个人信息报告",
    "views.report-problem.action.anonymous": "匿名报告",
    "views.report-problem.end.placeholder.title": "感谢您！",
    "views.report-problem.end.placeholder.message": "您的信息已发送。",
    "views.report-problem.end.placeholder.back": "返回",
    "views.report-problem.form.label.name": "姓名",
    "views.report-problem.form.label.phone": "联系电话",
    "views.report-problem.form.label.mail": "电子邮件",
    "views.report-problem.form.label.title": "标题",
    "views.report-problem.form.label.message": "信息",
    "views.report-problem.form.submit": "提交",
    "views.report-problem.validation.title": "请检查字段",
    "views.report-problem.validation.desc": "某些字段为空或格式不正确。请检查所有字段。",
    "views.report-problem.client.title": "客户端错误",
    "views.report-problem.client.desc": "没有网络",
    "views.report-problem.server.title": "服务器错误",
    "views.report-problem.server.desc": "发生了意外错误",

    // Survey
    "views.survey.title": "调查问卷",
    "views.survey.description": "为了更好地了解劳动剥削现象并规划我们的未来活动，您的见解和经验对于我们非常重要，您可以通过几个问题的匿名调查与我们分享这些信息。",
    "views.survey.start": "开始调查",
    "views.survey.exit.title": "您确定吗？",
    "views.survey.exit.desc": "您确定要退出调查吗？",
    "views.survey-welcome.title": "欢迎",
    "views.survey-welcome.placeholder.title": "开始调查",
    "views.survey.placeholder.content": "Maecenas sit amet sollicitudin dolor, quis fringilla tortor, quis fringilla tortor.",
    "views.survey.placeholder.button.content": "继续",
    "views.survey.button.next": "下一步",
    "views.survey.button.previous": "返回",
    "views.survey.end-screen.title": "感谢您！",
    "views.survey.end-screen.content": "这些数据将用于规划打击劳动剥削的活动并发展为潜在的人口贩卖受害者提供的服务。",
    "views.survey.end-screen.button.content": "返回",


    // Post
    "views.post.title": "新闻",

    // Resource placeholder
    "placeholder.network.title": "网络错误",
    "placeholder.network.message": "没有数据。请检查您的网络连接并重试。",
    "placeholder.network.action": "重试",

    // Menu
    "menu.home": "首页",
    "menu.anti-trafficking": "人口贩卖",
    "menu.eol": "劳动剥削",
    "menu.sos": "SOS",
    "menu.quiz": "测验",
    "menu.survey": "调查",
    "menu.report-problem": "报告问题",
    "menu.exit": "退出",

    // Misc
    "cancel": "取消",
    "confirm": "确认",
    "ok": "确定",
    "yes": "是",
    "no": "否",
    "translate": "翻译",
    "available-translations": "可用翻译",
    "auto-translate": "自动翻译",
    "share-news": "分享此新闻",
    "share-app": "分享应用程序",
    "share.try-astra-app": "试用 Astra 应用程序",
    "music": "音乐",
    "privacy-policy": "隐私政策",
  
    "dialog.survey.title": "新问卷！",
    "dialog.survey.message": "应用程序中有新的问卷可用。帮助我们实现目标，填写问卷吧！",
    "dialog.survey.later": "稍后",
    "dialog.survey.go": "开始问卷！",

    "dialog.share-app.title": "与他人分享 Astra 应用",

    // Level Stats
   "level-stats.current-level": "您的当前级别",

    "level-stats.level.1.title": "希望之火",
    "level-stats.level.1.desc": "您目前是“希望之火”！继续通过我们的教育平台学习，提高对人口贩卖的认识。",
    "level-stats.level.2.title": "正义的旅者",
    "level-stats.level.2.desc": "您的支持至关重要！您通过参与和提高意识做出了贡献。继续前进！",
    "level-stats.level.3.title": "自由之声",
    "level-stats.level.3.desc": "作为当前的“自由之声”，您对反人口贩卖的承诺激励了他人。继续引领变革的步伐！",

    "level-stats.upgraded.title": "恭喜！",
    "level-stats.upgraded.desc": "您已晋级到下一个级别！",

    "level-stats.more": "如何提升？",

    // Levels
    "views.levels.title": "级别",
    "views.levels.page-title": "参与并助力反人口贩卖的共同斗争",
    "views.levels.page-desc": "完成任务，成为反人口贩卖的真正战士。获取信息、参与测验和问卷，并与朋友分享应用。",

    "views.levels.quiz.title": "测验游戏",
    "views.levels.quiz.desc": "参与测验，测试您对人口贩卖的了解，并赢取 [n] 积分。",

    "views.levels.share.title": "分享应用",
    "views.levels.share.desc": "与您的朋友分享应用，赢取 [n] 积分。",

    "views.levels.survey.title": "问卷",
    "views.levels.survey.desc": "填写问卷并赢取 [n] 积分。"

}
