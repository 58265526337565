<template>

    <template v-if="loaded">
        <slot></slot>
    </template>
    <placeholder
        v-else
        icon="alert-circle"
        :title="lang('placeholder.network.title')"
    >
        <template v-slot:content>
            {{ lang('placeholder.network.message') }}
        </template>
        <template v-slot:button>
            <c-button :loading="loading" @click="loadResource">
                {{ lang('placeholder.network.action') }}
            </c-button>
        </template>
    </placeholder>

</template>

<script>

    import app from "../app";

    export default {

        props: {
            resource: {
                type: String,
                require: true
            },
        },

        data: () => {
            return {
                loading: false,
            }
        },

        computed: {
            loaded(){
                return app.resourceManager.isResourceLoaded(this.resource)
            }
        },

        methods: {

            async loadResource() {

                this.loading = true;

                try{
                    await app.resourceManager.loadResourceRemote(this.resource);
                }
                catch(e){
                    // ...
                }

                this.loading = false;

            }

        }
    }

</script>

<style lang="scss" scoped>
    @import "../scss/includes.scss";

</style>

