<template>
    <background>

        <div class="flex-layout">

            <view-header :title="lang('menu.eol')"></view-header>
          
            <div class="view p-lr page-eol">
                <resource-placeholder :resource="'eol'">
                    <div class="content">
                        <p class="description text-container"></p>

                        <div class="page-intro">
                            <p>{{ lang('views.exploitation.description') }}</p>
                        </div>

                        <router-link :to="generalInformation">
                            <button class="exploitation-button ripple">
                                <icon icon="info"></icon>
                                <div class="info">
                                    <p>{{ lang('views.exploitation.general.title') }}</p>
                                    <span>{{ lang('views.exploitation.general.desc') }}</span>
                                </div>
                            </button>
                        </router-link>

                        <hr class="separator">

                        <button class="exploitation-button ripple" @click="navigate('/eol/countries')">
                            <icon icon="globe"></icon>
                            <div class="info">
                                <p>{{ lang('views.exploitation.domestic.title') }}</p>
                                <span>{{ lang('views.exploitation.domestic.desc') }}</span>
                            </div>
                        </button>

                        <router-link :to="ifYouAreForeignerInSerbia">
                            <button class="exploitation-button ripple">
                                <img class="flag" :src="require('../assets/img/flags/rs.png')">
                                <div class="info">
                                    <p>{{ lang('views.exploitation.foreign.title') }}</p>
                                    <span>{{ lang('views.exploitation.foreign.desc') }}</span>
                                </div>
                            </button>
                        </router-link>

                    </div>
                </resource-placeholder>
            </div>
            
        </div>

    </background>
</template>

<script>


import settings from "../settings";
import helpers from "../helpers";

export default {


    computed: {

        

        ifYouAreForeignerInSerbia() {


            let resource = this.getResource('eol')
          

            return {
                path: '/post',
                query: {
                    id:  resource.data.attributes.EOLSPost.post.data.id,
                    cat: 'eol'
                }
            }

        },

        generalInformation() {

            return {
                path: '/eol/general',
            }

        },

    },

    mounted() {


    }

}


</script>


<style lang="scss" scoped>


@import '../scss/includes.scss';

.content {
    margin-top: 50px;


}


</style>
