<template>
    <background>

        <div class="flex-layout">

            <view-header :title="lang('menu.eol')"></view-header>

            <div class="view p-lr">
                <div class="content">

                    <div class="page-intro">
                        <h1>{{ lang('views.eol.country.title') }}</h1>
                        <p>{{ lang('views.eol.country.description') }}</p>
                    </div>

                    <router-link v-for="country in countries" :to="route(country.original)" :key="country.id">
                        <button class="select-country ripple">
                            <img class="flag" v-if="showImages" :src="thumbnailUrl(country.original)">
                            <div class="flag" v-else></div>
                            <span>{{ country.localized.attributes.previewTitle }}</span>
                        </button>
                    </router-link>

                </div>
            </div>

        </div>

    </background>
</template>

<script>


import settings from "../settings";

export default {

    data: function(){
        return {
            showImages: false,
        }
    },

    computed: {

        countries() {

            let resource = this.getResource('eol');

            return resource.data.attributes.EOLFPosts.posts.data.map( (q) => {
                return {
                    original: q,
                    localized: this.strapiLocalizedResource(q)
                }
            } ).filter( q => q.localized )

        },

    },

    methods: {

        thumbnailUrl( country ){

            if(
                !country.attributes.thumbnail ||
                !country.attributes.thumbnail.data ||
                !country.attributes.thumbnail.data.attributes.formats
            )
                return null;



            return settings.serverBaseUri + country.attributes.thumbnail.data.attributes.formats.thumbnail.url

        },

        route( country ){

            return {
                path: '/post',
                query: {
                    id: country.id,
                    cat: 'eol'
                }
            }

        }

    },

    mounted() {

        setTimeout( () => {
            this.showImages = true
        }, 500 )

    }

}


</script>


<style lang="scss" scoped>


@import '../scss/includes.scss';

.content {
    margin-top: 50px;
    margin-bottom: 50px;

    .select-country {
        @include glass-style();

        margin-bottom: 15px;
        width: 100%;
        height: $form-one-line-input-height;
        padding: 0 12px;
        border-radius: $default-border-radius-large;
        color: white;
        font-family: inherit;
        display: flex;
        align-items: center;
        text-align: left;

        .flag {

            object-fit: cover;
            object-position: center;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: color(1, "dark-red");
        }

        span {
            text-transform: uppercase;
            font-weight: 500;
            font-size: 12px;
            margin-left: 15px;


        }
    }


}


</style>
