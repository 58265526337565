<template>
    <button @click="$store.commit('toggleMenu')"  :class="{disabled: disabled}">
        <icon icon="menu"></icon>
    </button>
</template>

<script>

export default {

    props: {
        disabled: {
            type: Boolean,
            default: false,
        }
    }

}

</script>

<style lang="scss" scoped >

    @import "../scss/includes.scss";

    button{
        color: white;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        background: none;
        outline: none;
        border: none;

        &.disabled{
            opacity: 0.5;
            pointer-events: none;
        }

    }

    button .icon{
        font-size: 26px;
    }


</style>