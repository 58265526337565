<template>
    <div class="article ripple">
        <div class="image" 
            :class="{placeholder: imagePlaceholderDisplayed}"
            :style="{backgroundImage: 'url(' + imageURLComputed + ')'}">
        </div>

        <div class="text-container">
            <p>{{ limitStr(title, 75) }}</p>
            <span>{{meta}}</span>
        </div>
    </div>
</template>

<script>

    export default {
        props: {
            title: {
                type: String,
                required: true,
            },
            imageUrl: {
                type: String,
                required: true,
            },
            meta: {
                type: String,
                required: true,
            },
        },
        computed: {
            imageURLComputed () {

                if(this.imageUrl)
                    return this.imageUrl

                return require('../assets/img/placeholder.jpg')

            },
            
            imagePlaceholderDisplayed () {
                return !this.imageUrl
            }
        }
    }


</script>

<style lang="scss" scoped>

    @import "../scss/includes.scss";

    .article{
        @include glass-style();
        overflow: hidden;

        $size: 90px;

        height: $size;
        border-radius: $default-border-radius-large;
        width: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;



        .image{
            height: 100%;
            width: $size;
            min-width: $size;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

            &.placeholder{
                background-size: 180%;
            }

        }

        .text-container{

            display: flex;
            flex-direction: column;
            padding: 0px 15px;

            p{
                text-align: left;
                font-size: 0.9em;
                line-height: 1.4em;
                font-weight: 400;
            }
            span{
                font-size: 0.8em;
                margin-bottom: 5px;
                color: rgba(255, 255, 255, 0.2);
            }
        }
    }


</style>