<template>
    <div>
        <template v-if="posts.length">
            <router-link
                    v-if="posts"
                    v-for="post in postsLocalized"
                    class="tab-button ripple"
                    :to="route(post.original)"
            >
                <div class="info">
                    <p>{{ post.localized ? post.localized.attributes.previewTitle : post.original.attributes.previewTitle }}</p>
                </div>
                <icon icon="arrow-right"></icon>
            </router-link>
        </template>
    </div>
</template>

<script>

    export default {

        props: {
            posts: {
                type: Array,
                required: true,
            },
            category: {
                type: String,
                required: false,
            },
        },

        computed: {

            postsLocalized(){

                return this.posts.map( (q) => {
                    return {
                        original: q,
                        localized: this.strapiLocalizedResource(q)
                    }
                } )

            }

        },

        methods: {

            route( post ){

                return {
                    path: '/post',
                    query: {
                        id: post.id,
                        cat: this.category
                    }
                }

            }

        },



    }

</script>

<style lang="scss" scoped>

    @import "../scss/includes.scss";



</style>
