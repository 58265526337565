import helpers from "../helpers";
import moment from "moment";
import settings from "../settings";
import lang from "./lang";
import app from "../app";
import { Share } from '@capacitor/share';

export default {

    methods: {

        /**
         *
         * @param string
         * @returns {void|*}
         */
        decodeHTMLEntities(string){
            return helpers.decodeHTMLEntities(string)
        },


        /**
         *
         * @param date
         * @returns {string}
         */
        formatDate(date){
            return moment(date).format('DD.MM.YYYY HH:mm')
        },

        /**
         *
         * @param str
         * @param count
         * @returns {string}
         */
        limitStr(str, count= 40){
            return str.slice(0, count) + (str.length > count ? '...' : '');
        },

        /**
         *
         * @param str
         * @param count
         * @returns {string}
         */
        shareApp(){

            Share.share({
                dialogTitle: lang.methods.lang("dialog.share-app.title"),
                text: lang.methods.lang("share.try-astra-app"),
                url: settings.appUrl,
            }).then(function() {

                let totalShares = app.getConfig('totalShares')
                app.setConfig('totalShares', totalShares ? parseInt(totalShares) + 1 : 1);

            }).catch(function(error) {

                console.log('Error sharing:', error)

            });

        },


        /**
         *
         * @param str
         * @param count
         * @returns {string}
         */
        levelColor( index ){

            return settings.levelColors[index - 1]

        },



    }


}
