import langEn from '../lang/en';
import langSr from '../lang/sr';
import langEs from '../lang/es';
import langZh from '../lang/zh';
import langHi from '../lang/hi';
import langRu from '../lang/ru';

export default {

    availableLanguages: [
        {
            key: 'sr',
            label: 'Srpski',
            data: langSr
        }, {
            key: 'en',
            label: 'English',
            data: langEn
        }, {
            key: 'es',
            label: 'Español',
            data: langEs
        }, {
            key: 'zh',
            label: '中文',
            data: langZh
        }, {
            key: 'hi-IN',
            label: 'हिंदी',
            data: langHi
        }, {
            key: 'ru',
            label: 'Русский',
            data: langRu
        }
    ]


}