<template>

    <div class="confirm-dialog" :class="{visible}">

            
        <img src="../assets/img/intro-1.png" alt="">

        <div class="box">

            <div class="title">
                <span>{{ lang('dialog.survey.title') }}</span>
            </div>

            <p class="message">{{ lang('dialog.survey.message') }}</p>

            <div class="buttons">

                <button class="ripple primary" @click="handleButtonClick()">
                    {{ lang('dialog.survey.go') }}
                </button>
                <button class="ripple" @click="cancel">
                    {{ lang('dialog.survey.later') }}
                </button>

            </div>
        </div>

    </div>

</template>

<script>

    export default {

        data() {
            return {
                visible: false,
            }
        },

        methods: {

            handleButtonClick(button){

                this.$emit("end")
                this.navigate('/survey')

            },

            cancel(button){

                this.$emit("end")

            }

        },

        mounted(){

            setTimeout( () => {
                this.visible = true
            }, 100 )

        }

    }

</script>

<style lang="scss" scoped>

    @import "../scss/includes.scss";

    .confirm-dialog{


        opacity: 0;
        transition: all 0.1s ease-in-out;
        transform: scale(1.1);
        display: block;

        .box{
            color: black;
            width: 320px;
            border-radius: 15px;
            box-shadow: 0 0 20px rgba(0,0,0,0.7);
            display: flex;
            flex-direction:column;
            overflow: hidden;
        }


        &.visible{
            opacity: 1;
            transform: scale(1);
        }

        img{
            width: 70px;
            margin: 0 auto;
            display: block;
            margin-top: -30px;
            position: absolute;
            left: 0;
            right: 0;
            
        }

        .title{
            font-size: 1.2em;
            padding: 20px;
            font-weight: 500;
            background: url('../assets/img/background-dialog.png');
            background-size: cover;
            color: white;
            text-align: center;
            padding-top: 50px;
        }

        p{
            padding: 20px;
            line-height: 1.4em;
            flex: 1;
            background: white;
            text-align: center;
        }

        .buttons{
            border-top: 1px solid #efefef;
            padding: 20px;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            background: white;
        }

        button{
            background: transparent;
            margin-top: 10px;
            color: black;
            height: 30px;
            padding: 0 20px;
            font-family: inherit;
            text-transform: uppercase;
            font-weight: 600;
            border-radius: $default-border-radius-small;
            border: none;
            margin-left: 10px;

            &.primary{
                color: white;
                background: #822202;
            }
        }

    }

</style>












