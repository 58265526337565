<template>
    <router-link to="/sos" class="sos-button" :class="{disabled: disabled}">
        <icon icon="phone-call"></icon>
        <span class="title">SOS</span>
    </router-link>
</template>

<script>

export default {

    props: {
        disabled: {
            type: Boolean,
            default: false,
        }
    }

}

</script>

<style lang="scss" scoped >

    @import "../scss/includes.scss";

    a{
        color: white;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;

        &.disabled{
            opacity: 0.5;
            pointer-events: none;
        }

    }

    a .icon{
        font-size: 22px;
    }

    span{
        display: block;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 13px;
        margin-left: 5px;
        line-height: 1em;
    }

</style>