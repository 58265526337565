<template>
    <div class="post">
        <div class="image" :style="{backgroundImage: 'url(' + imageUrl + ')'}">
        </div>

        <div class="text-container ripple">
            <p>{{ title }}</p>
        </div>
    </div>
</template>

<script>

    export default {
        props: {
            title: {
                type: String,
                required: true,
            },
            imageUrl: {
                type: String,
                required: true,
            },
        }
    }


</script>

<style lang="scss" scoped>

    @import "../scss/includes.scss";

    .post{
        @include glass-style();

        border-radius: $default-border-radius-large;
        width: 100%;
        overflow: hidden;

        .image{

            width: 100%;
            height: 115px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }

        .text-container{
            min-height: 80px;
            display: flex;
            justify-content: center;
            padding: 10px;
            text-align: center;
            font-size: 0.95em;
            line-height: 1.2em;
        }
    }


</style>