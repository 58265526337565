<template>
    <background>

        <div class="flex-layout ">

            <view-header
                :disable-menu-drawer="true"
                :title="lang('views.post.title')"
            ></view-header>

            <div class="view p-lr post-container hide-during-transition" v-if="post">

                <img v-if='wordpressPostHasFeaturedImage(post)' :src="wordpressPostFeaturedImage(post, 'medium')"  class="featured">
                <h1>{{decodeHTMLEntities(post.title.rendered)}}</h1>
                
                <post-content  class='content' :content="post.content.rendered">
                </post-content>  

                <div class="simple-button button-share ripple" @click="share">
                    <icon icon="share-2"></icon>
                    <span>{{lang("share-news")}}</span>
                </div>

            </div>

            <div class="view p-lr" v-else>
                <placeholder
                              icon="alert"
                              title="No post with requested parameters" >
                </placeholder>
            </div>

        </div>

    </background>
</template>

<script>

    import { Share } from '@capacitor/share';

    export default {

        data(){
            return{
                post: null,
            }
        },

        methods: {

            share(){

                Share.share({
                    'url': this.post.link,
                }).then(function() {
                    console.log('Successful share');
                }).catch(function(error) {
                    console.log('Error sharing:', error)
                });

            }

        },

        beforeMount(){

            let posts = this.wpLocalizedResource('posts', this.$route.query.lang);
            let post;

            posts.forEach((p) => {
                if(p.id == this.$route.query.post){
                    post = p;
                }
            })

            if(this.$route.query.example)
                this.post = posts[0];

            this.post = post;

        }

    }



</script>

<style scoped>

    .button-share{
        margin-top: 40px;
        margin-bottom: 70px;
    }

    h1{
        line-height: 1.4em;
        text-align: center;
        margin-top: 60px;
    }

    img.featured{
        margin-top: 50px;
        width: 100%;
        height: calc(50vw * 1);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 15px;
        margin-bottom: 30px;

    }


</style>
