import axios from 'axios'
import ConfirmDialog from './components/ConfirmDialog.vue'
import SurveyDialog from './components/SurveyDialog.vue'
import { createApp } from 'vue'
import langMixin from './mixins/lang'
import navMixin from './mixins/navigate'
import router from './router'
import lang from './lang'

Math.easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
};

export default {

    /**
     * https://stackoverflow.com/questions/19269545/how-to-get-a-number-of-random-elements-from-an-array
     * @param arr
     * @param n
     * @returns {any[]}
     */
    getRandomElementsFromArray(arr, n) {

        var result = new Array(n),
            len = arr.length,
            taken = new Array(len);

        if (n > len)
            throw new RangeError("getRandom: more elements taken than available");

        while (n--) {
            var x = Math.floor(Math.random() * len);
            result[n] = arr[x in taken ? taken[x] : x];
            taken[x] = --len in taken ? taken[len] : len;
        }
        return result;

    },

    /**
     * https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
     * @param array
     * @returns {*}
     */
    shuffleArray(array) {

        var currentIndex = array.length, temporaryValue, randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        return array;

    },

    /**
     *
     * @param str
     * @param n
     * @param useWordBoundary
     * @returns {string|*}
     */
    truncate( str, n, useWordBoundary = true){
        if (str.length <= n) { return str; }
        const subString = str.substr(0, n-1); // the original check
        return (useWordBoundary
            ? subString.substr(0, subString.lastIndexOf(" "))
            : subString) + "&hellip;";
    },

    /**
     *
     * @returns {string}
     */
    publicPath( path ){

        if(window.device.platform == 'Android'){
            return 'file:///android_asset/www'  + path
        }

        return '' + path

    },

    /**
     *
     * @param options
     */
    confirmDialog( options ){

        let vueApp;
        let node;

        options.onEnd = () => {
            vueApp.unmount();
            document.body.removeChild(node);
        }

        vueApp = createApp(ConfirmDialog, options)

        node = document.createElement('div');
        node.classList.add('dialog-container')

        document.body.appendChild(node);

        vueApp.mount(node)

    },

    /**
     *
     * @param options
     */
    surveyDialog(){

        let vueApp;
        let node;

        let options = {
            onEnd: () => {
                vueApp.unmount();
                document.body.removeChild(node);
            }
          
        }

        vueApp = createApp(SurveyDialog, options)
        vueApp.mixin(langMixin)
            .mixin(navMixin)
            .use(router)

        node = document.createElement('div');
        node.classList.add('dialog-container')

        document.body.appendChild(node);

        vueApp.mount(node)

    },

    /**
     *
     * @param str
     */
    decodeHTMLEntities (str) {
        if(str && typeof str === 'string') {
            // strip script/html tags
            str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
            str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');

            var element = document.createElement('div');

            element.innerHTML = str;
            str = element.textContent;
            element.textContent = '';

            return str;
        }
    },

    /**
     *
     * @param name
     */
    playSound( name ){

        var audio = new Audio('/sounds/' + name + '.mp3');
        audio.play();

    },

    /**
     *
     * @param url
     * @returns {Promise<unknown>}
     */
    preloadImage( url ){

        return new Promise( (resolve, reject) => {

            let img = new Image();
            img.src= url;

            img.onload = resolve
            img.onerror = reject

        } )

    },


    /**
     *
     * @param images
     * @returns {Promise<unknown[]>}
     */
    preloadImages( images ){

        let promises = [];

        for(let i = 0; i < images.length; i++){

            promises.push(this.preloadImage(images[i]));

        }

        return Promise.all(promises)

    },

    /**
     * 
     * @param {*} url 
     * @returns string 
     */
    parseYoutubeVideoURL( url ){

        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return (match&&match[7].length==11)? match[7] : false;

    }

}
