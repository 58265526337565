<template>
    <div class="progress-bar" style="margin-top:50px">
        <div class="bar" ref="bar">
            <div class="progress" :style="{width: progressWidth + 'px'}">

            </div>
        </div>
        <div class="value">
            <span class="title">
                Question
            </span>
            <div>
                <span>{{value}}/</span>
                <span>{{total}}</span>
            </div>
        </div>

    </div>
</template>


<script>

    export default{
        data(){
            return {
                barWidth: null,
            }
        },
        props: {
            value: {
                required: true,
            },
            total: {
                required: true,
            },
        },
        methods: {
            getBarWidth(){
                return this.barWidth;
            }
        },
        computed: {
            progressWidth(){
                let ratio = this.value / this.total;
                    if(ratio <= 1)
                        return ratio * this.getBarWidth();
                return 1 * this.getBarWidth();
            }
        },
        mounted(){
            this.barWidth = this.$refs.bar.offsetWidth

        }
    }

</script>

<style lang="scss" scoped>
    @import "../scss/includes.scss";

    .progress-bar{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .bar{
            position: relative;
            width: 70%;
            height: 6px;
            background-color: rgba(255,255,255,0.2);
            border-radius: $default-border-radius-large;
            .progress{
                @include glass-style-yellow();
                border: none;
                position: absolute;
                box-sizing: border-box;
                height: 100%;
                width: 10%;
                transition: width 2s;
                border-radius: $default-border-radius-large;
            }
        }
        .value{
            //margin-left: 60px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;

            .title{
                font-size: 0.7em;
                color: rgba(255, 255, 255, 0.6);
                margin-bottom: 5px;
            }

            div{
                span:first-child{
                    font-size: 1.2em;
                    font-weight: 600;
                }

                span:last-child{
                    opacity: 0.8;
                    font-size: 0.75em;
                    font-weight: 600;
                }
            }

        }
    }
</style>