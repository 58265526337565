<template>
    <div class="progress-ring">

        <svg :width="size" :height="size" :style="{'min-width': size+'px'}">
            <circle
                    :stroke-width="strokeWidth"
                    stroke="rgba(255,255,255,0.10)"
                    fill="transparent"
                    :r="radius"
                    :cx="size/2"
                    :cy="size/2"
            ></circle>
            <circle
                    class="progress"
                    :stroke-width="strokeWidth"
                    :stroke-dasharray="dashArray"
                    :stroke-dashoffset="dashOffset"
                    stroke="white"
                    fill="transparent"
                    :r="radius"
                    :cx="size/2"
                    :cy="size/2"
            ></circle>
        </svg>

        <div class="value">

            <div>
                <span>{{value}}/</span>
                <span>{{total}}</span>
            </div>

        </div>


    </div>
</template>

<script>

    import feather from 'feather-icons'

    export default {

        props: {
            value: {
                required: true,
            },
            total: {
                required: true,
            },
            size: {
                required: true,
            },
            strokeWidth: {
                required: false,
                default: 6
            }
        },

        computed: {

            radius(){
                return (this.size/2) - (this.strokeWidth*2)
            },

            circumference(){
                return 2 * this.radius * Math.PI;
            },

            dashArray(){
                return `${this.circumference} ${this.circumference}`
            },

            dashOffset(){
                return this.circumference - ( this.value / this.total * this.circumference );
            }

        },

        data() {
            return {
            }
        },

        methods: {

        },

        mounted(){
        }

    }


</script>

<style lang="scss" scoped>

    @import '../scss/includes.scss';

    .progress-ring{
        position: relative;
        display: inline-block;
    }

    svg circle {

        &.progress{
            transition: 0.35s stroke-dashoffset;
            // axis compensation
            transform: rotate(-90deg);
            transform-origin: 50% 50%;
            stroke: color(1, "yellow");
            filter: drop-shadow( 0px 0px 3px color(1, "yellow"));
        }

    }

    .value{
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        span:first-child{
            font-size: 1.2em;
            font-weight: 600;
        }

        span:last-child{
            opacity: 0.8;
            font-size: 0.75em;
            font-weight: 600;
        }

    }

</style>