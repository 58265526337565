<template>
    <background>

        <div class="flex-layout">

            <top-bar id="top-bar" :class="{collapse: collapse}">
                <div class="view p-lr p-tb">

                    <div class="top-menu-left-btn">
                        <sos-button></sos-button>
                    </div>

                    <div class="top-menu-right-btn">
                        <menu-button></menu-button>
                    </div>

                    <img id="logo" src="../assets/img/logo.svg" alt="">

                    <hr class="separator">

                    <div class="social-networks">
                        <div class="row">
                            <div class="col col-3">
                                <a :href="settings('fbPageUrl')" target="_blank">
                                    <button class="ripple">
                                        <icon icon="facebook"></icon>
                                    </button>
                                </a>
                            </div>
                            <div class="col col-3">
                                <a :href="settings('ytPageUrl')" target="_blank">
                                    <button class="ripple">
                                        <icon icon="youtube"></icon>
                                    </button>
                                </a>
                            </div>
                            <div class="col col-3">
                                <a :href="settings('instaPageUrl')" target="_blank">
                                    <button class="ripple">
                                        <icon icon="instagram"></icon>
                                    </button>
                                </a>
                            </div>
                            <div class="col col-3">
                                <a :href="settings('twPageUrl')" target="_blank">
                                    <button class="ripple">
                                        <icon icon="twitter"></icon>
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>

                    <hr class="separator">

                    <user-level-stats></user-level-stats>

                    <div class="buttons">
                        <div class="level-more ripple" @click="navigate('/levels')">
                            <span>{{ lang("level-stats.more") }}</span>
                            <icon icon="arrow-right"></icon>
                        </div>
                    </div>

                </div>
            </top-bar>

            <div class="view p-lr main" ref="main">

                <div class="icon-container">
                    <icon class='pull-up' icon="chevrons-up"></icon>
                </div>


                
                <div class="row main-menu" v-if="$store.getters.isActiveSurveyAvailable">
                    <div class="col col-3">
                        <c-square-button icon="star" @click="navigate('/quiz-welcome')">
                            {{ lang("menu.quiz") }}
                        </c-square-button>
                    </div>
                    <div class="col col-3">
                        <c-square-button icon="astra" @click="navigate('/anti-trafficking')">
                            {{ lang("menu.anti-trafficking") }}
                        </c-square-button>
                    </div>
                    <div class="col col-3">
                        <c-square-button icon="users" @click="navigate('/eol')">
                            {{ lang("menu.eol") }}
                        </c-square-button>
                    </div>
                    <div class="col col-3">
                        <c-square-button icon="edit" @click="navigate('/survey-welcome')">
                            {{ lang("menu.survey") }}
                        </c-square-button>
                    </div>
                </div>
                <div class="row main-menu" v-else>
                    <div class="col col-4">
                        <c-square-button icon="star" @click="navigate('/quiz-welcome')">
                            {{ lang("menu.quiz") }}
                        </c-square-button>
                    </div>
                    <div class="col col-4">
                        <c-square-button icon="astra" @click="navigate('/anti-trafficking')">
                            {{ lang("menu.anti-trafficking") }}
                        </c-square-button>
                    </div>
                    <div class="col col-4">
                        <c-square-button icon="users" @click="navigate('/eol')">
                            {{ lang("menu.eol") }}
                        </c-square-button>
                    </div>
                </div>


                <section-title icon="info">{{ lang("views.home.news-activities-title") }}</section-title>

                <div class="posts">

                    <resource-placeholder :resource="wpLocalizedResourceName('posts')">

                        <div class="row items">

                            <div class="col col-12 col-md-6 col-ld-4" v-for="post in posts" :key="post.id">
                                <router-link :to="wpPostResourceLink(post)">
                                    <post
                                        :image-url="wordpressPostFeaturedImage(post, 'medium')"
                                        :title="decodeHTMLEntities(post.title.rendered)"
                                        :meta="formatDate(post.date)">
                                    </post>
                                </router-link>
                            </div>

                            <div class="more-news">
                                <a :href="settings('newsPageUrl')" target="_blank">
                                    <c-button>
                                        {{lang('views.home.more')}}
                                    </c-button>
                                </a>
                            </div>

                        </div>

                    </resource-placeholder>

                </div>

            </div>

            <div class="go-to-top" :class="{visible: goToTop}">
                <c-square-button
                 icon="chevron-up" 
                 :solid="true" 
                 @click="scrollToTop"
                 size="small">
                </c-square-button>
            </div>

        </div>

    </background>
</template>

<script>

import settings from '../settings'
import app from "../app";


export default {
    name: 'Home',

    data() {
        return {
            progressValue: 0,
            collapse: false,
            goToTop: false,
        }
    },

    computed: {


        posts() {

            return this.wpLocalizedResource('posts')

        },


    },

    methods: {

        settings(key){
            return settings[key]
        },

        async loadResource() {

            this.loading = true;

            await app.resourceManager.loadResourceRemote('posts');

            this.loading = false;

        },

        scrollToTop(){

            $(this.$refs.main).animate({ 
                scrollTop: 0 
            });

        }

    },

    beforeUnmount() {

        this.$refs.main.removeEventListener('scroll', this.onScroll);

    },

    watch: {


    },

    mounted() {

        this.onScroll = (e) => {
            this.collapse = e.target.scrollTop > 150
            this.goToTop = e.target.scrollTop > 250
        }

        this.$refs.main.addEventListener('scroll', this.onScroll)


    }

}

</script>

<style lang="scss" scoped>

@import "../scss/includes.scss";

#top-bar{
    position: absolute;
    transition: all 0.4s ease-in-out;
    left: 0;
    width: 100%;

    &.collapse{
        transform: translate3d(0, -450px, 0);
    }

}

.level-stats{
    margin-top: 20px;
}

.icon-container{

    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    margin-top: -70px;

    .pull-up{
        font-size: 40px;
        opacity: 0.5;
        text-align: center;
    }

}


#logo {
    width: 120px;
    display: block;
    margin: 0 auto;
    margin-bottom: 30px;
}

.view.main{
    padding-top: calc(410px + var(--safe-area-inset-top)) !important;
}

.posts{
    min-height: 250px;
}

.social-networks {

    padding: 0 12%;

    .col {

    }

    button {
        outline: none;
        border: none;
        background: none;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        margin: 0px auto;
        color: white;
    }

}

.buttons { 
    display: flex;
    margin-top: 15px;
    margin-bottom: -7px;
    justify-content: center;

    .level-more {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.8em;
        color: white;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 0.5px;
        cursor: pointer;
        padding: 2px 10px;
        border-radius: 4px;

        span {
            margin-right: 5px;
        }

        icon {
            font-size: 0.8em;
        }
    }

}

.main-menu {
    margin: 30px -10px;
}

.posts .col {

    margin-bottom: 20px;
}

.more-news {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 50px;
}

.go-to-top{
    position: fixed;
    bottom: 40px;
    right: 40px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    &.visible{
        opacity: 1;
    }

}

</style>
