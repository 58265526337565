<template>
    <background>

        <div class="flex-layout">

            <view-header :title="lang('views.levels.title')"></view-header>

            <div class="view p-lr">
                <div class="content">
                    <div class="page-intro">

                        <h1>{{ lang("views.levels.page-title") }}</h1>

                        <div class="badges">
                            <badge :color="levelColor(1)"></badge>
                            <icon icon="arrow-right"></icon>
                            <badge :color="levelColor(2)"></badge>
                            <icon icon="arrow-right"></icon>
                            <badge :color="levelColor(3)"></badge>
                        </div>

                        <p>{{ lang('views.levels.page-desc') }}</p>

                    </div>

                    <user-level-stats class="summary"></user-level-stats>

                    <div class="stat-item ripple" @click="shareThisApp">

                        <div class="left">
                            <div class="title">{{ lang("views.levels.share.title") }}</div>
                            <div class="desc">{{ lang("views.levels.share.desc", {"[n]": $store.getters.shareTotalPoints}) }}</div>
                            <div class="progress-c">
                                <div class="progress">
                                    <div class="bar" :style="{width: $store.getters.shareProgress + '%'}"></div>
                                </div>
                                <div class="score-stat">
                                    <div class="current">{{ $store.getters.sharePoints }}</div>
                                    <div class="total">/{{ $store.getters.shareTotalPoints }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="right">
                            <icon icon="chevron-right"></icon>
                        </div>

                    </div>
                    <div class="stat-item ripple" @click="navigate('/quiz-welcome')">

                        <div class="left">
                            <div class="title">{{ lang("views.levels.quiz.title") }}</div>
                            <div class="desc">{{ lang("views.levels.quiz.desc", {"[n]": $store.getters.quizTotalPoints}) }}</div>
                            <div class="progress-c">
                                <div class="progress">
                                    <div class="bar" :style="{width: $store.getters.quizProgress + '%'}"></div>
                                </div>
                                <div class="score-stat">
                                    <div class="current">{{ $store.getters.quizPoints }}</div>
                                    <div class="total">/{{ $store.getters.quizTotalPoints }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="right">
                            <icon icon="chevron-right"></icon>
                        </div>

                    </div>

                    <div class="stat-item ripple" @click="showSurvey">

                        <div class="left">
                            <div class="title">{{ lang("views.levels.survey.title") }}</div>
                            <div class="desc">{{ lang("views.levels.survey.desc", {"[n]": $store.getters.surveyTotalPoints}) }}</div>
                            <div class="progress-c">
                                <div class="progress">
                                    <div class="bar" :style="{width: $store.getters.surveyProgress + '%'}"></div>
                                </div>
                                <div class="score-stat">
                                    <div class="current">{{ $store.getters.surveyPoints }}</div>
                                    <div class="total">/{{ $store.getters.surveyTotalPoints }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="right">
                            <icon icon="chevron-right"></icon>
                        </div>

                    </div>

                    
                </div>
            </div>
        </div>

    </background>

</template>

<script>
import app from '../app';


    export default {
        data(){
            return {
                loading: false
            }
        },

        methods: {

            shareThisApp(){

                this.shareApp();
                
            },

            showSurvey(){

                if(!app.isActiveSurveyAvailableForUser())
                    return;
 
                this.navigate('/survey-welcome')
                
            },

        }
    }

</script>

<style lang="scss" scoped>

@import "../scss/includes.scss";

.summary{
    margin-bottom: 30px;
}

.page-intro{
    margin-bottom: 30px !important;

    h1{
        text-transform: none !important;
        margin-bottom: 20px !important;
    }

    p{
        font-size: 0.85em !important;
    }

    .badges{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin-bottom: 10px;

        .badge{
            width: 20px;
        }
    }
}

.stat-item{

    display: flex;
    align-items: center;
    padding: 15px;
    border: 1px solid rgba(255,255,255,0.1);
    border-radius: 7px;
    margin-bottom: 10px;

    .right{
        width: 50px;
        min-width: 50px;
        text-align: right;
    }

    .left{
        flex: 1;
    }

    .title{
        font-size: 1.0em;
        margin-bottom: 5px;
    }

    .desc{
        font-size: 0.80em;
        margin-bottom: 10px;
        line-height: 1.4em;
        opacity: 0.7;
    }

    .icon{
        font-size: 1.4em;
    }

    .progress-c{
        display: flex;
        align-items: center;
        gap: 10px
    }

    .progress{
        height: 5px;
        background: transparentize( color(2, "yellow"), 0.9);
        border-radius: 5px;
        overflow: hidden;
        width: 40%;

        .bar{
            height: 100%;
            background: color(2, "yellow");
        }
    }

    .score-stat{
        font-size: 0.85em;
        color: color(2, "yellow");
    }

}


</style>