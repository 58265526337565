import store from './store'
import localforage from 'localforage';
import axios from 'axios'

export default class HTTPManager {

    /**
     *
     */
    constructor() {

        this.locked = false;
        this.storageKey = 'httpQueue'
        this.maxTries = 3

        this.restartQueue();

    }

    /**
     *
     */
    restartQueue(){

        if(this.queueInterval)
            clearInterval(this.queueInterval)

        console.log('Queue started/restarted')

        this.queueInterval = setInterval(() => {this.processQueue}, 60000 )
        this.processQueue();



    }

    /**
     *
     * @param data
     */
    async request( data ){

        // Add to Queue
        await this.pushRequestToQueue( data )

        // Try to submit immediately
        this.restartQueue();

    }

    /**
     *
     * @returns {Promise}
     */
    waitForUnlock() {

        return new Promise((resolve, reject) => {

            let check = () => {

                if (!this.locked)
                    resolve();

                setTimeout(check, 200)
            }

            check()

        })

    }

    /**
     *
     * @param data
     * @returns {Promise}
     */
    async pushRequestToQueue(data) {

        await this.waitForUnlock();

        this.locked = true;

        let queue = await localforage.getItem(this.storageKey)

        console.log(queue)

        queue = queue || []

        queue.push({
            requestData: data,
            tries: 0,
            time: new Date()
        })

        await localforage.setItem(this.storageKey, queue)

        this.locked = false;

        return Promise.resolve()

    }

    /**
     *
     * @param data
     * @returns {Promise}
     */
    async processQueue() {

        console.log('Processing queue')

        await this.waitForUnlock();

        this.locked = true;

        let queue = await localforage.getItem(this.storageKey)

        queue = queue || []

        if(queue.length)
            for(let i = queue.length - 1; i >= 0; i --) {

                let removeFromQueue = false

                console.log('Processing request: ' + queue[i].requestData.url )

                queue[i].tries++

                try{

                    await axios.request( queue[i].requestData )
                    removeFromQueue = true;

                } catch (e) {

                    // When e.status is 0 or null -> Network error (no internet)

                    if (e.status && queue[i].tries == this.maxTries) {
                        removeFromQueue = true
                    }

                }

                if(removeFromQueue){
                    queue.splice(i, 1);
                }

            }

        await localforage.setItem(this.storageKey, queue)

        // Stop queue if empty
        if(!queue.length && this.queueInterval){
            clearInterval(this.queueInterval)
            console.log('Queue stopped')
        }

        this.locked = false;

        return Promise.resolve()

    }


}