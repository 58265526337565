<template>
    <div class="top-bar">

        <div class="circle">
            <div class="background"></div>
        </div>

        <div class="content">
            <slot></slot>
        </div>

    </div>
</template>

<style lang="scss" scoped >

    @import "../scss/includes.scss";

    .top-bar{
        position: relative;

        &::after{
            position: absolute;
            left: 0;
            right: 0;
            height: 100px;
            bottom: -70px;
            pointer-events: none;
            content: "";
            z-index: -1;
            background-image: url('../assets/img/shadow.png');
            background-size: auto 100%;
        }

    }

    .circle{
        $width: 2700px;
        position: absolute;
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
        width: $width;
        margin-left: -$width/2;
        left: 50%;
        height: 900px;
        bottom: 0;
        overflow: hidden;
        // box-shadow: 0 0 50px rgba(0,0,0,0.5);

        

        .background{
            position: absolute;
            width: 100vw;
            height: 60vh;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            bottom: 0;
            background-image: url('../assets/img/background-blurred.png');
            background-size: cover;
            background-position: center top;

        }



    }

    .content{
        position: relative;
    }


</style>













