<template>
    <background>

        <div class="flex-layout">

            <view-header :title="lang('menu.quiz')"></view-header>

            <div class="view p-lr">

                <resource-placeholder resource="quizAndSurvey">

                    <placeholder
                        icon="star"
                        :title="lang('views.quiz.title')"
                    >

                        <template v-slot:content>

                            <p>
                                {{ lang('views.quiz.description') }}
                            </p>

                        </template>

                        <template v-slot:additional>


                            <router-link
                                    v-for="quiz in quizzes"
                                    class="tab-button ripple"
                                    :class="{done: quiz.score.points == quiz.score.total}"
                                    :to="route(quiz.original)"
                            >
                                <div class="info">
                                    <span class="title">{{ quiz.localized.attributes.title }}</span>
                                    <badge color="#d28700" ></badge>
                                    <div class="score-stat">
                                        <div class="current">{{ quiz.score.points }}</div>
                                        <div class="total">/{{ quiz.score.total }}</div>
                                    </div>
                                </div>
                                <icon icon="arrow-right"></icon>
                            </router-link>

                        </template>


                    </placeholder>

                </resource-placeholder>

            </div>
        </div>

    </background>

</template>

<script>

import app from '../app';
import settings from '../settings';

export default {
    data() {
        return {
            loading: false,
        }
    },


    computed: {

        quizzes(){

            let resource = this.getResource('quizAndSurvey');

            if(!resource)
                return [];

            let score = app.getConfig('quizScore')

            if(score)
                score = JSON.parse(score)

            return resource.data.attributes.activeQuizzes.quizzes.data.map( (q) => {

                let correctAnswers = score && score[q.id] ? score[q.id] : 0;
                let currentScore = correctAnswers * settings.questionPoint;
                let totalScore = q.attributes.questions.length * settings.questionPoint;

                return {
                    original: q,
                    localized: this.strapiLocalizedResource(q),
                    score: {
                        points: currentScore,
                        total: totalScore,
                    }
                }
            } ).filter( q => q.localized )

        }

    },


    methods: {

        route(quiz) {

            return {
                path: '/quiz',
                query: {
                    id: quiz.id,
                }
            }

        },

    },


}
</script>

<style scoped lang="scss" >

@import "../scss/includes.scss";

.tab-button.done{

    .badge{
        display: block;
    }

    
    .score-stat{
        color: color(1, "yellow");
        opacity: 1;
    }

}

.info{
    display: flex;
    align-items: center;
    flex: 1;
    padding-right: 40px;

    .title{
        flex: 1
    }

    .badge{
        display: none;
        font-size: 20px;
        height: 20px;
        margin-right: 7px;
        font-size: 1.3em;
    }

    .score-stat{
        display: flex;
        margin-bottom: -3px;
        opacity: 0.7;
    }

}

</style>