<template>
    <background>

        <div class="flex-layout">

            <view-header
                    :disable-menu-drawer="true"
                    :title="title"
            ></view-header>

            <div class="view p-lr post-container" v-if="post">

                <a target="_blank" :href="editRoute" class="edit-page" v-if="editable">
                    <icon icon="edit"></icon>
                    <span>Uredi</span>
                </a>

                <div class="page-intro">
                    <h1>{{post.attributes.previewTitle}}</h1>
                </div>

                <post-content-strapi :post="post.attributes"></post-content-strapi>

            </div>

            <div class="view p-lr" v-else>
                <placeholder
                        icon="alert"
                        title="No post with requested parameters" >
                </placeholder>

            </div>

        </div>

    </background>
</template>

<script>

    import app from "../app";
    import settings from "../settings";

    export default {

        data(){
            return{
                title: null,
                post: null,
            }
        },

        computed:{

            resources() {
                return this.$store.state.resources
            },
            editable() {
                return app.getConfig('editorMode')
            },
            editRoute(){
                return settings.serverBaseUri + "/admin/content-manager/collectionType/api::post.post/" + this.post.id + "?plugins[i18n][locale]=" + app.getConfig('lang')
            },
        },

        methods: {


            setupPost() {

                let resource = this.getResource('posts');

                let allPosts = JSON.parse(JSON.stringify(resource.data));

                allPosts.forEach( (post) => {

                    if( this.$route.query.id == post.id )
                        this.post = this.strapiLocalizedResource(post);

                } )

                if(this.$route.query.cat == 'eol'){
                    this.title = this.lang('menu.eol')
                }
                else if(this.$route.query.cat == 'at'){
                    this.title = this.lang('menu.anti-trafficking')
                }

            },

        },

        watch: {

            resources: {
                handler() {
                    this.setupPost()
                },
                deep: true,
            }

        },

        beforeMount(){

            if(!this.isResourceLoaded('posts'))
                return;

            this.setupPost();

        }



    }



</script>

<style scoped lang="scss">

    h1{
        font-size: 1.12em;
        line-height: 1.4em;
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
        margin-top: 50px;
    }

    h2{
        font-size: 0.9em;
        opacity: 0.8;
        text-align: center;
        margin-top: 3px;
        margin-bottom: 50px;
    }

    .edit-page{
        position: fixed;
        z-index: 10;
        top: 120px;
        right: 0;
        height: 40px;
        padding: 0 20px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 0.9em;
        background: black;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon{
            height: 16px;
            margin-right: 5px;
        }

    }

    .post-container{
        padding-bottom: 30px;
    }

</style>
