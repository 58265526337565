<template>
    <div class="article-aside">
        <div class="image" :style="{backgroundImage: 'url(' + imageUrl + ')'}">
        </div>

        <div class="text-container ripple">
            <p>{{ title }}</p>
            <span>{{ subTitle }}</span>
        </div>
    </div>
</template>

<script>

    export default {
        props: {
            title: {
                type: String,
                required: true,
            },
            imageUrl: {
                type: String,
                required: true,
            },
            subTitle: {
                type: String,
                required: true,
            },

        }
    }


</script>

<style lang="scss" scoped>

    @import "../scss/includes.scss";

    .article-aside{
        @include glass-style();

        display: flex;
        flex-direction: row;

        border-radius: $default-border-radius-large;
        width: 100%;
        height: 100px;
        overflow: hidden;

        .image{

            height: 100%;
            width: 105px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            flex-shrink: 0;
        }

        .text-container{

            display: flex;
            width: 100%;
            flex-direction: column;
            justify-content: center;
            padding: 15px 15px; // Podjednako ?

            p {
                text-align: left;
                font-size: 0.9em;
                line-height: 1.2em;
                font-weight: 600;
                text-transform: uppercase;
            }
            span {
                margin-top: 10px;
                font-size: 0.8em;
                color: rgba(255, 255, 255, 0.5);
            }
        }

    }


</style>