import {createApp} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import app from './app'
import localforage from 'localforage'

// Capacitor
import { SplashScreen } from '@capacitor/splash-screen';
import { App as CapacitorApp } from '@capacitor/app';
import { StatusBar, Style } from '@capacitor/status-bar';

// Mixins
import langMixin from './mixins/lang'
import navMixin from './mixins/navigate'
import resourceMixin from './mixins/resource'
import helpersMixin from './mixins/helpers'

// Global SCSS
import './scss/style.scss'

// Create App
let vueApp = createApp(App)

// Offline storage
localforage.config({
    driver: localforage.INDEXEDDB,
    name: 'astra',
    version: 1.0,
});

// jQuery - for Owl
window.jQuery = require('jquery');
window.$ = window.jQuery
require('owl.carousel');

console.log("Environment: ", process.env.NODE_ENV);

// Components
vueApp.component('background', require('./components/Background.vue').default)
vueApp.component('c-select', require('./components/Select.vue').default)
vueApp.component('c-button', require('./components/Button.vue').default)
vueApp.component('c-square-button', require('./components/ButtonSquare.vue').default)
vueApp.component('icon', require('./components/Icon.vue').default)
vueApp.component('top-bar', require('./components/TopBar.vue').default)
vueApp.component('progress-ring', require('./components/ProgressRing.vue').default)
vueApp.component('section-title', require('./components/SectionTitle.vue').default)
vueApp.component('page', require('./components/Page.vue').default)
vueApp.component('page-aside', require('./components/PageAside.vue').default)
vueApp.component('post', require('./components/Post.vue').default)
vueApp.component('language-picker', require('./components/LanguagePicker.vue').default)
vueApp.component('sos-button', require('./components/SOSButton.vue').default)
vueApp.component('menu-button', require('./components/MenuButton.vue').default)
vueApp.component('drawer-menu', require('./components/DrawerMenu.vue').default)
vueApp.component('view-header', require('./components/ViewHeader.vue').default)
vueApp.component('placeholder', require('./components/Placeholder.vue').default)
vueApp.component('resource-placeholder', require('./components/ResourcePlaceholder.vue').default)
vueApp.component('spiner', require('./components/Spiner.vue').default)
vueApp.component('progress-bar', require('./components/ProgressBar.vue').default)
vueApp.component('post-content', require('./components/PostContent.vue').default)
vueApp.component('post-content-strapi', require('./components/PostContentStrapi.vue').default)
vueApp.component('post-menu', require('./components/PostMenu.vue').default)
vueApp.component('user-level-stats', require('./components/UserLevelStats.vue').default)
vueApp.component('badge', require('./components/Badge.vue').default)

// Setup
vueApp.use(store)
    .use(router)
    .mixin(langMixin)
    .mixin(navMixin)
    .mixin(resourceMixin)
    .mixin(helpersMixin)


CapacitorApp.addListener('backButton', ({canGoBack}) => {
    if(!canGoBack){
        CapacitorApp.exitApp();
    } else {
        window.history.back();
    }
});

StatusBar.setStyle({ style: Style.Dark });
StatusBar.setBackgroundColor({ color: '#160001' });
 
app.initialize().then(async () => {

    if (!app.getConfig('notFirstTime')) {

        await router.replace({
            path: '/language',
            query: {
                redirect: '/welcome'
            }
        })

        app.setConfig('notFirstTime', true)

    }

    // Editor mode
    let url = new URL(window.location.href);

    if (url.searchParams.get('editorMode')) {
        app.setConfig('editorMode', 1)
    }

    // Load images and stuff
    try{
        await app.preloadImageResources();
    }
    catch (e) {
        console.log("Cannot preload resources", e);
    }

    vueApp.mount('#app')

    SplashScreen.hide();

}).catch((e) => {

    console.error("Failed to initialize app: ", e)
    document.querySelector('#app').innerHTML = e;

})



