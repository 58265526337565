import lang from '../lang'
import app from '../app'

export default {

    methods: {

        /**
         *
         * @param key
         * @returns {*}
         */
        lang(key, replace = {}){


            let activeLang = app.getConfig('lang');

            for( let i = 0; i < lang.availableLanguages.length; i++ ){

                if(activeLang === lang.availableLanguages[i].key){

                    if(lang.availableLanguages[i].data[key]){

                        let str = lang.availableLanguages[i].data[key]

                        for(let key in replace){
                            str = str.replace(key, replace[key])
                        }

                        return str

                    }

                        

                }

            }

            return '-----';

        },


    }



}