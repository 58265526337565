export default {

    "views.language.title": "Select language",
    "views.language.confirm": "Confirm",

    // Intro
    "views.intro.1.title": "Welcome",
    "views.intro.2.title": "The fight against human trafficking",
    "views.intro.3.title": "Learn more about human trafficking",
    "views.intro.4.title": "Fill out the survey",
    "views.intro.1.description": "ASTRA is a non-governmental organization dedicated to eradicating all forms of human trafficking and exploitation",
    "views.intro.2.description": "The fight against human trafficking must be comprehensive, which implies action in the field of prevention, protection and support for victims as well as prosecution of perpetrators.",
    "views.intro.3.description": "This application will give you basic information about human trafficking and employment conditions in Serbia and abroad, how to protect yourself, as well as useful contacts in the country and abroad.",
    "views.intro.4.description": "In order to act as efficiently as possible, ASTRA is conducting a voluntary survey. Participate with us in combating human trafficking",
    "views.intro.button.next": "Next",
    "views.intro.button.survey": "Survey",
    "views.intro.button.survey.skip": "Skip",
    "views.intro.button.start.survey": "Start survey",
    "views.intro.button.back.survey": "Go Back",
    "views.intro.button.start.game": "Start quiz",

    // SOS
    "views.sos.description": "If you need additional preventive information, have dilemmas, recognize some form of human trafficking in your environment or need help and support, you can contact us",
    "views.sos.report-problem.title": "Report a problem",
    "views.sos.report-problem.message": "Contact us via the form",
    "views.sos.phone.1.message": "Available 24/7",
    "views.sos.phone.2.message": "Available 24/7 (free from the territory of Serbia)",
    "views.sos.fb.title": "Facebook/Messenger",
    "views.sos.fb.message": "Official facebook page",
    "views.sos.mail.title": "E-Mail",
    "views.sos.mail.message": "Contact us via E-mail (sos@astra.rs)",

    //Country residence
    "views.eol.country.title": "Select country",
    "views.eol.country.description": "If you are considering looking for a job abroad, we have prepared for you useful information about employment conditions in some European countries.",

    //Exploitation
    "views.exploitation.description":"Collect information and inform yourself about your rights before making a decision about employment.",
    "views.exploitation.general.title": "Useful information",
    "views.exploitation.general.desc": "Useful information about working exploitation",
    "views.exploitation.domestic.title": "If you are abroad",
    "views.exploitation.domestic.desc": "For Serbian citizens",
    "views.exploitation.foreign.title": "If you are a foreigner in Serbia",
    "views.exploitation.foreign.desc": "For foreign citizens in Serbia",

    // Home
    "views.home.quiz.title": "Quiz",
    "views.home.quiz.description": "Learn more about anti-trafficking through quiz game",
    "views.home.quiz.play": "Play",
    "views.home.news-activities-title": "News & Activities",
    "views.home.more": "More news",

    // Astra services
    "views.astra-services.title": "Services provided by Astra",
    "views.astra-services.desc-short": "Learn more about services",
    "views.astra-services.desc": "On this page you can find information about the services Astra provides",

    // Quiz
    "views.quiz.title": "Quiz",
    "views.quiz.description": "Learn more about human trafficking through an interactive quiz. Choose one of the quizzes.",
    "views.quiz.play":"Start Quiz",
    'views.quiz.stats.question': 'Question',
    'views.quiz.stats.score': 'Score',
    'views.quiz.stats.time': 'Time',
    'views.quiz.end.title': 'End of the Game',
    'views.quiz.end.description': 'Thank you for taking the quiz.',
    'views.quiz.end.correct-answers': 'Correct answers',
    'views.quiz.end.incorrect-answers': 'Incorrect answers',
    'views.quiz.end.question-summary': 'Questions',
    'views.quiz.timeout': 'Your time is up',
    'views.quiz.action.next': 'Next',
    'views.quiz.action.ok': 'OK',
    'views.quiz.action.skip': 'Skip',
    'views.quiz.action.confirm': 'Confirm',
    'views.quiz.info': 'Info',
    'views.quiz.right': 'You are right.',
    'views.quiz.wrong': 'Unfortunately, you made a mistake. ',

    
    'views.back.exit.title': 'Are you sure?',
    'views.back.exit.desc': 'Are you sure you want to exit? Your actual data will be lost (score etc.)',

    // Anti trafficking
    "views.anti-trafficking.title": "Anti trafficking",

    // Report problem
    "views.report-problem.intro.description": "If you feel insecure, afraid or don't want to share your personal data with us, you can also write to us anonymously.",
    "views.report-problem.form.d.description": "Dear, thank you for your trust. Please leave your contact information in the report and describe the situation you are reporting in as much detail as possible. Expect us to contact you as soon as possible. ",
    "views.report-problem.form.a.description": "Dear, Thank you for your trust. Considering the specificity of the anonymous report, we will be able to take actions in relation to the amount of information you share with us. Please describe the problem in as much detail as possible and, if possible, the way to contact a person who is at potential risk of becoming a victim of human trafficking.",
    "views.report-problem.action.data": "Report with personal data",
    "views.report-problem.action.anonymous": "Anonymous report",
    "views.report-problem.end.placeholder.title": "Thank you!",
    "views.report-problem.end.placeholder.message": "Your message has been sent.",
    "views.report-problem.end.placeholder.back": "Back",
    'views.report-problem.form.label.name': "First and last name",
    'views.report-problem.form.label.phone': "Contact phone",
    'views.report-problem.form.label.mail': "E-mail",
    'views.report-problem.form.label.title': "Title",
    'views.report-problem.form.label.message': "Message",
    'views.report-problem.form.submit': "Submit",
    'views.report-problem.validation.title': 'Please validate the fields',
    'views.report-problem.validation.desc': 'Some fields are empty or not in the correct format. Please check all fields.',
    'views.report-problem.client.title': 'Client Error',
    'views.report-problem.client.desc': 'No network',
    'views.report-problem.server.title': 'Server error',
    'views.report-problem.server.desc': 'An unexpected error occurred',

    // Survey
    "views.survey.title": "Survey",
    "views.survey.description": "In order to gain a better insight into the phenomenon of labor exploitation and plan our future activities, your knowledge and experiences that you can share with us through a few questions in an anonymous survey would be of great importance to us.",
    "views.survey.start": "Start Survey",
    'views.survey.exit.title': 'Are you sure?',
    'views.survey.exit.desc': 'Are you sure you want to exit the survey?',
    "views.survey-welcome.title": "Welcome",
    "views.survey-welcome.placeholder.title": "Start Survey",
    "views.survey.placeholder.content": "Maecenas sit amet sollicitudin dolor, quis fringilla tortor, quis fringilla tortor.",
    "views.survey.placeholder.button.content": "Continue",
    "views.survey.button.next": "Next",
    "views.survey.button.previous": "Back",
    "views.survey.end-screen.title": "Thank you!",
    "views.survey.end-screen.content": "This data will be used for the purpose of planning activities to combat labor exploitation and development of services provided to (potential) victims of human trafficking.",
    "views.survey.end-screen.button.content": "Back",

    // Post
    'views.post.title': 'News',

    // Resource placeholder
    'placeholder.network.title': 'Network error',
    'placeholder.network.message': 'There is no data available. Please check your internet connection and try again.',
    'placeholder.network.action': 'Try again',

    // Menu
    "menu.home": "Home",
    "menu.anti-trafficking": "Anti trafficking",
    "menu.eol": "Exploitation of labour",
    "menu.sos": "SOS",
    "menu.quiz": "Quiz",
    "menu.survey": "Survey",
    "menu.report-problem": "Report problem",
    "menu.exit": "Exit",

    // Misc
    "cancel": "Cancel",
    "confirm": "Confirm",
    "ok": "OK",
    "yes": "Yes",
    "no": "No",
    "translate": "Translate",
    "available-translations": "Available translations",
    "auto-translate": "Auto-translate",
    "share-news": "Share this news",
    "share-app": "Share this app",
    "share.try-astra-app": "Try Astra App",
    "music": "Music",
    "privacy-policy": "Privacy policy",

    "dialog.survey.title": "New survey available!",
    "dialog.survey.message": "New survey is available in app. Help us on a mission. Participate in survey!",
    "dialog.survey.later": "Later",
    "dialog.survey.go": "Take me to survey!",

    
    "dialog.share-app.title": "Share Astra App with others",

    // Level Stats
    "level-stats.current-level": "Your current level",

    "level-stats.level.1.title": "Spark of Hope",
    "level-stats.level.1.desc": "You are currently a 'Spark of Hope'! Raise awareness about human trafficking through our educational platform.",
    "level-stats.level.2.title": "Justice Voyager",
    "level-stats.level.2.desc": "Your support is crucial! You have contributed through your engagement and awareness-raising. Keep up the progress!",
    "level-stats.level.3.title": "Voice of Freedom",
    "level-stats.level.3.desc": "As the current 'Voice of Freedom,' your dedication to the fight against human trafficking inspires others. Keep setting the standards for change!",

    "level-stats.upgraded.title": "Congratulations!",
    "level-stats.upgraded.desc": "You've upgraded to the next level!",

    "level-stats.more": "More about levels",

    // Levels
    "views.levels.title": "Levels",
    "views.levels.page-title": "Engage and contribute to the collective fight against human trafficking",
    "views.levels.page-desc": "Complete tasks to become a true fighter against human trafficking. Get informed, participate in quizzes and surveys, and share the app with your friends.",

    "views.levels.quiz.title": "Quiz Game",
    "views.levels.quiz.desc": "Take part in the quiz, test your knowledge about human trafficking, and you can earn [n] points.",

    "views.levels.share.title": "Share the App",
    "views.levels.share.desc": "Share the app with your friends and earn [n] points.",

    "views.levels.survey.title": "Survey",
    "views.levels.survey.desc": "Complete the survey and earn [n] points."


}