import {createStore} from 'vuex'
import app from '../app'
import settings from '../settings'

export default createStore({
    state: {

        // Default configuration
        config : {
            lang: 'sr'
        },

        // Menu
        menuVisible: false,

        // Resources
        resources: {},

        // Transitions
        transitionsActive: true,

        // Status bar padding
        statusBarPadding: false,

    },

    getters: {

        /**
         *
         * @param state
         * @returns {state.config|{lang}}
         */
        config: state => {
            return state.config
        },

        /**
         *
         * @param state
         * @returns {any}
         */
        survey: state => {
            return state.resources.survey
        },

        /**
         *
         * @param state
         */
        resources: state => {
            return state.resources
        },

        /**
         * 
         * @param {*} state 
         * @returns 
         */
        isActiveSurveyAvailable: state => {
            return app.isActiveSurveyAvailableForUser()
        },

        /**
         * Quiz Points
         * @returns 
         */
        quizPoints() {

            let resource = app.resourceManager.getResource('quizAndSurvey');
            let score = app.getConfig('quizScore');

            if (!score || !resource)
                return 0;

            resource = resource.data

            score = JSON.parse(score)

            let total = 0;

            if (!resource.data.attributes.activeQuizzes)
                return 0

            resource.data.attributes.activeQuizzes.quizzes.data.forEach((quiz) => {

                Object.keys(score).forEach((key) => {

                    if (quiz.id == key) {
                        total += score[key];
                    }

                })

            })

            return total * settings.questionPoint;

        },

        /**
         * Totao Quiz Points
         * @returns 
         */
        quizTotalPoints() {

            let resource = app.resourceManager.getResource('quizAndSurvey');

            let total = 0;

            if (!resource)
                return;

            resource = resource.data

            resource.data.attributes.activeQuizzes.quizzes.data.forEach((quiz) => {
                total += quiz.attributes.questions.length;
            })

            return total * settings.questionPoint;

        },

        /**
         * Quiz Progress
         * @returns 
         */
        quizProgress(state, getters){
            
           return Math.floor(getters.quizPoints / getters.quizTotalPoints * 100);
           
        },

        /**
         * Share Points
         * @returns 
         */
        sharePoints() {

            let countShares = 0;

            if(app.getConfig('totalShares'))
                countShares = app.getConfig('totalShares') > settings.sharesRecommended ? settings.sharesRecommended : app.getConfig('totalShares');

            return countShares * settings.oneSharePoints;

        },

        /**
         * Total Share Points
         * @returns 
         */
        shareTotalPoints() {

            return settings.sharesRecommended * settings.oneSharePoints;

        },

        /**
         * Share Progress
         * @returns 
         */
        shareProgress(state, getters){

            return Math.floor(getters.sharePoints / getters.shareTotalPoints * 100);
           
        },

        /**
         * Share Points
         * @returns 
         */
        surveyPoints() {

            if(app.isAnySurveySubmitted())
                return settings.surveyPoints

            return 0

        },

        /**
         * Total Share Points
         * @returns 
         */
        surveyTotalPoints() {

            return settings.surveyPoints
        
        },

        /**
         * Survey Progress
         * @returns 
         */
        surveyProgress(state, getters){

            return Math.floor(getters.surveyPoints / getters.surveyTotalPoints * 100);
           
        },

        /**
         * Current Points
         * @returns 
         */
        currentPoints(state, getters) {

            return getters.sharePoints + getters.quizPoints + getters.surveyPoints;

        },

        /**
         * Total Points
         * @returns 
         */
        totalPoints(state , getters) {

           return getters.quizTotalPoints + getters.shareTotalPoints + getters.surveyTotalPoints;

        },

        /**
         * Progress
         * @returns 
         */
        scoreProgress(state , getters) {

           return Math.floor(getters.currentPoints / getters.totalPoints * 100);

        },

        /**
         * Progress
         * @returns 
         */
        scoreColor(state , getters) {

           if(settings.levelColors[getters.currentLevel - 1])
                return settings.levelColors[getters.currentLevel - 1]

            settings.levelColors[0]

        },

        /**
         * Current User Level
         * @returns 
         */
        currentLevel(state , getters) {

            // Requires at least one share
            let pointsNeedToGetFinalLevel = getters.totalPoints - settings.oneSharePoints;

            // If 90% of the points are reached, the user is in last level
            if(getters.currentPoints >= pointsNeedToGetFinalLevel)
                return settings.totalLevels
            
            // Rest will be equally distributed
            return Math.floor(getters.currentPoints / pointsNeedToGetFinalLevel * (settings.totalLevels - 1)) + 1

        },

    },
    mutations: {

        /**
         * @param state
         * @param config
         */
        setConfig(state, config){
            state.config = config
        },

        /**
         *
         * @param state
         * @param resources
         */
        setResources(state, resources){
            state.resources = resources
        },

        /**
         * @param state
         * @param visible
         */
        setMenuVisible(state, visible){
            state.menuVisible = visible
        },

        /**
         * @param state
         */
        toggleMenu(state){
            state.menuVisible = !state.menuVisible
        },

        /**
         *
         * @param state
         * @param active
         */
        setTransitionsActive(state, active){
            state.transitionsActive = active
        },

        /**
         *
         * @param state
         * @param active
         */
        setStatusBarPaddingActive(state, active){
            state.statusBarPadding = active
        },

    },
    actions: {


    },
    modules: {}
})
