<template>
    <div class="drawer-menu-container" :class="{visible: $store.state.menuVisible, padding: $store.state.statusBarPadding}">
        <div class="overlay" @click="$store.commit('setMenuVisible', false)"></div>
        <div class="menu">
            <div class="scroll">

                <div class="content">
                    
                    <div class="top-menu-right-btn">
                        <menu-button></menu-button>
                    </div>

                    <div class="logo">
                        <img src="../assets/img/logo.svg" alt="" />
                    </div>

                    <div class="top-menu-left-btn language">
                        <language-picker></language-picker>
                    </div>

                    <ul>
                        <li @click="goTo('/')">
                            <div class="icon">
                                <icon icon="home"></icon>
                            </div>
                            <span>{{lang("menu.home")}}</span> 
                        </li>
                        <li @click="goTo('/anti-trafficking')">
                            <div class="icon">
                                <icon icon="astra"></icon>
                            </div>
                            <span>{{lang("menu.anti-trafficking")}}</span>
                        </li>
                        <li @click="goTo('/eol')">
                            <div class="icon">
                                <icon icon="users"></icon>
                            </div>
                            <span>{{lang("menu.eol")}}</span>
                        </li>
                        <li @click="goTo('/sos')">
                            <div class="icon">
                                <icon icon="phone-call"></icon>
                            </div>
                            <span>{{lang("menu.sos")}}</span>
                        </li>
                        <li @click="goTo('/quiz-welcome')">
                            <div class="icon">
                                <icon icon="star"></icon>
                            </div>
                            <span>{{lang("menu.quiz")}}</span>
                        </li>
                        <li @click="goTo('/survey-welcome')" v-if="$store.getters.isActiveSurveyAvailable">
                            <div class="icon">
                                <icon icon="edit"></icon>
                            </div>
                            <span>{{lang("menu.survey")}}</span>
                        </li>
                        <li @click="goTo('/report')">
                            <div class="icon">
                                <icon icon="send"></icon>
                            </div>
                            <span>{{lang("menu.report-problem")}}</span>
                        </li>
                        <li @click="exitApp()">
                            <div class="icon">
                                <icon icon="log-out"></icon>
                            </div>
                            <span>{{lang("menu.exit")}}</span>
                        </li>
                    </ul>

                    <div class="music ripple" @click="toggleAudio">
                        <icon icon="music"></icon>
                        <div class="info">
                            {{lang("music")}}
                        </div>
                        <div class="status">
                            {{audio ? "on" : "off"}}
                        </div>
                    </div>

                    
                    <div class="links">
                        <a href="https://astra.rs/" target="_blank">
                            <span class="website" >www.astra.rs</span>
                        </a> 
                        <a href="https://astra.rs/politika-privatnosti/" target="_blank">
                            <span class="privacy" >{{lang("privacy-policy")}}</span>
                        </a> 
                    </div>

    
                </div>


            </div>
        </div>
    </div>
</template>

<script>
import app from '../app';


    export default {
        data() {
            return {
                audio: false,
            }
        },
        methods: {

            /**
             *
             * @param page
             */
            goTo(page){

                this.$store.commit('setMenuVisible', false)

                setTimeout( () => {
                    this.navigate(page);
                }, 200 )

            },

            /**
             *
             * @param page
             */
            exitApp(){

                if (navigator.app) {
                    navigator.app.exitApp();
                } else if (navigator.device) {
                    navigator.device.exitApp();
                } else {
                    window.close();
                }

            },

            /**
             *
             * @param page
             */
            toggleAudio(){

                app.setMusic(!this.audio)
                this.updateAudioState()

            },

            /**
             *
             * @param page
             */
            updateAudioState(){

                this.audio = app.musicStatus()

            },


        },

        mounted() {

            this.updateAudioState();

        }


    }

</script>

<style lang="scss" scoped>

    @import "../scss/includes.scss";

    .drawer-menu-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        pointer-events: none;


        &.visible {

            pointer-events: all;

            .overlay {
                opacity: 1;
            }

            .menu {
                transform: translate3d(0px, 0, 0);
                opacity: 1;
            }

        }

    }

    .menu {
        box-sizing: border-box; 
        width: 70%;
        position: absolute;
        height: 100%;
        top: 0;
        right: 0;
        background: url('../assets/img/background-blurred.png');
        background-size: cover;
        background-position: center top;
        transform: translate3d(50px, 0, 0);
        opacity: 0;
        transition: transform 0.2s ease-in-out, opacity 0.15s cubic-bezier(0.65, 0, 0.35, 1);
        padding-top: #{var(--safe-area-inset-top)};

        &.padding{
            padding-top: $status-bar-padding;
        }

        .scroll {
            position: relative;
            overflow-y: auto;
            height: 100%;

            .content {
                position: relative;
                min-height: 530px;
                height: 100%;
            }
        }

    }


    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    .logo{
        padding: 0 $default-page-padding - 7;
        padding-bottom: 50px;
        padding-top: 100px;
        
        img {
            width: 120px;
        }
    }
    

    .language{
        position: absolute;
    }

    .music{
        margin-top: 70px;
        padding: 0 $default-page-padding;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-size: 0.9em;
        font-weight: 500;
        height: 45px;

        .icon {
            color: white;
            font-size: 20px;
            margin-right: 5px;
            width: 24px;
        }

        .status{
            width: 40px;
            margin-left: 20px;
        }
    }

    ul {
        width: 100%;

        li {
            @extend %ripple;
            display: flex;
            align-items: center;
            padding: 0 $default-page-padding;
            height: 45px;
            cursor: pointer;

            &:not(:last-child) {
                border-bottom: 1px solid rgba(255, 255, 255, 0.12);
            }

            .icon {
                color: white;
                font-size: 20px;
                margin-right: 5px;
                width: 24px;
            }

            span {
                text-transform: uppercase;
                font-weight: 600;
                font-size: 12px;
                line-height: 12px;
            }

        }
    }

    .links{
        padding-top: $default-page-padding;
        padding-bottom: $default-page-padding;

        a {
            font-size: 0.85em;
            opacity: 0.7;
            padding-left: $default-page-padding;
            display: block;
        }
    }

    


</style>
