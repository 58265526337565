import {createRouter, createWebHashHistory} from 'vue-router'

import Home from '../views/Home.vue'
import WelcomePage from '../views/WelcomePage.vue'
import Language from '../views/Language.vue'
import AntiTrafficking from '../views/AntiTrafficking.vue'
import Survey from '../views/Survey.vue'
import SurveyWelcome from '../views/SurveyWelcome.vue'
import ReportProblemForm from '../views/ReportProblemForm.vue'
import Sos from '../views/Sos.vue'
import ReportProblem from '../views/ReportProblem.vue'
import Post from '../views/Post.vue'
import Quiz from '../views/Quiz.vue'
import QuizWelcome from "../views/QuizWelcome";
import EOLCountries from '../views/EOLCountries.vue'
import EOL from '../views/EOL.vue'
import app from "../app";
import WPPost from "../views/WPPost";
import EOLGeneral from "../views/EOLGeneral";
import Levels from "../views/Levels";
import AstraServices from "../views/AstraServices";
import settings from '../settings'

const routes = [
    {
        path: '/',
        component: Home,
        meta: {
            depth: 10,
        },
    },
    {
        path: '/language',
        component: Language,
        meta: {
            depth: 0,
        },
    },
    {
        path: '/welcome',
        component: WelcomePage,
        meta: {
            depth: 0,
        },
    },
    {
        path: '/anti-trafficking',
        component: AntiTrafficking,
        meta: {
            depth: 20
        },
    },
    {
        path: '/survey',
        component: Survey,
        meta: {
            depth: 20
        },
    },
    {
        path: '/quiz-welcome',
        component: QuizWelcome,
        meta: {
            depth: 20
        },
    },
    {
        path: '/quiz',
        component: Quiz,
        meta: {
            depth: 21
        },
    },
    {
        path: '/sos',
        component: Sos,
        meta: {
            depth: 20
        },
    },
    {
        path: '/sos/astra-services',
        component: AstraServices,
        meta: {
            depth: 30
        },
    },
    {
        path: '/report',
        component: ReportProblem,
        meta: {
            depth: 30
        },
    },
    {
        path: '/report/form',
        component: ReportProblemForm,
        meta: {
            depth: 31
        },
    },
    {
        path: '/wp-post',
        component: WPPost,
        meta: {
            depth: 50
        },
    },
    {
        path: '/post',
        component: Post,
        meta: {
            depth: 50
        },
    },
    {
        path: '/levels',
        component: Levels,
        meta: {
            depth: 50
        },
    },
    {
        path: '/survey-welcome',
        component: SurveyWelcome,
        meta: {
            depth: 20
        },
    },
    {
        path: '/eol',
        component: EOL,
        meta: {
            depth: 30
        },
    },
    {
        path: '/eol/general',
        component: EOLGeneral,
        meta: {
            depth: 31
        },
    },
    {
        path: '/eol/countries',
        component: EOLCountries,
        meta: {
            depth: 31
        },
    },

]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from) => {

    // StatusBar.backgroundColorByHexString(to.meta.statusBarBg || settings.statusBarBgColorDark);

})

router.afterEach((to, from) => {

    to.meta.transitionName = to.meta.depth < from.meta.depth ? 'slide-down' : 'slide-up'

    
})

export default router
