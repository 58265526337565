import helpers from '../helpers';

export default {

    methods: {

        // TODO : Move this to Quiz page as it is used only there
        //

        initializePopStateListener( canExit ){

            history.pushState(null, null, document.URL);

            this.listener = () => {

                if ( canExit && canExit() ) {
                    window.removeEventListener('popstate', this.listener);
                    this.$router.go(-1)
                }
                else{

                    history.pushState(null, null, document.URL);
                    
                    helpers.confirmDialog({
                        title: this.lang('views.back.exit.title'),
                        message: this.lang('views.back.exit.desc'),
                        buttons: [{
                            label: this.lang('cancel'),
                        }, {
                            label: this.lang('yes'),
                            primary: true,
                            callback: () => {
                                window.removeEventListener('popstate', this.listener);
                                this.$router.go(-2)
                            }
                        }]
                    })
                }

                

            }

            window.addEventListener('popstate', this.listener);
        },

        deinitializePopStateListener(){
            if (this.listener)
                window.removeEventListener('popstate', this.listener);
        },

        buttonAction(steps = -3){
            this.deinitializePopStateListener();

            this.$router.go(steps);
        },

    }

}