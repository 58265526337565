<template>
    <background>

        <div class="flex-layout">

            <view-header :title="lang('menu.survey')"></view-header>

            <div class="view p-lr">

                <resource-placeholder resource="quizAndSurvey">

                    <placeholder
                        icon="bar-chart"
                        :title="lang('views.survey.title')"
                    >

                        <template v-slot:content>
                            {{ lang('views.survey.description') }}
                        </template>

                        <template v-slot:button>
                            <c-button @click="startSurvey" v-if="$store.getters.isActiveSurveyAvailable">
                                {{ lang('views.survey.start') }}
                            </c-button>
                        </template>

                    </placeholder>

                </resource-placeholder>

            </div>
        </div>

    </background>

</template>

<script>

    import app from '../app';

    export default {
        data(){
            return {
                loading: false
            }
        },

        methods: {
            startSurvey(){
                this.$router.push({
                    path: 'survey',
                });
            },
        }
    }
</script>
