export default {

    "views.language.title": "Selección de idioma",
    "views.language.confirm": "Confirmar",

    // Intro
    "views.intro.1.title": "Bienvenido/a",
    "views.intro.2.title": "La lucha contra la trata de personas",
    "views.intro.3.title": "Obtenga más información sobre la trata de personas",
    "views.intro.4.title": "Complete la encuesta",
    "views.intro.1.description": "ASTRA es una organización no gubernamental dedicada a erradicar todas las formas de trata y explotación de personas",
    "views.intro.2.description": "La lucha contra la trata de personas debe ser integral, lo que implica acciones en el ámbito de la prevención, protección y apoyo a las víctimas así como el procesamiento de los perpetradores.",
    "views.intro.3.description": "Esta aplicación le brindará información básica sobre la trata de personas y las condiciones de empleo en Serbia y en el extranjero, cómo protegerse, así como contactos útiles en el país y en el extranjero.",
    "views.intro.4.description": "Para actuar de la forma más eficaz posible, ASTRA realiza una encuesta voluntaria. Únase a nosotros en la lucha contra la trata de personas",
    "views.intro.button.next": "Siguiente",
    "views.intro.button.survey": "Encuesta",
    "views.intro.button.survey.skip": "Saltarlo",
    "views.intro.button.start.survey": "Iniciar una encuesta",
    "views.intro.button.back.survey": "Atrás",
    "views.intro.button.start.game": "Empezar el juego",

    // SOS
    "views.sos.description": "Si necesita información preventiva adicional, tiene dilemas, reconoce alguna forma de trata de personas en su entorno o necesita ayuda y apoyo, puede ponerse en contacto con nosotros",
    "views.sos.report-problem.title": "Informar un problema",
    "views.sos.report-problem.message": "Contáctenos a través del formulario",
    "views.sos.phone.1.message": "Disponible 24/7",
    "views.sos.phone.2.message": "Disponible 24/7 (gratuito del territorio de Serbia)",
    "views.sos.fb.title": "Facebook/Messenger",
    "views.sos.fb.message": "Pagina oficial de Facebook",
    "views.sos.mail.title": "Correo electrónico",
    "views.sos.mail.message": "Contáctenos por correo electrónico (sos@astra.rs)",

    //Country residence
    "views.eol.country.title": "Selección de país",
    "views.eol.country.description": "Si está pensando en buscar trabajo en el extranjero, hemos preparado para usted información útil sobre las condiciones laborales en algunos países europeos.",

    //Exploitation
    "views.exploitation.description":"Reúna información e infórmese sobre sus derechos antes de tomar una decisión sobre el empleo.",
    "views.exploitation.general.title": "Información útil",
    "views.exploitation.general.desc": "Información útil sobre la explotación laboral",
    "views.exploitation.domestic.title": "Si está en el extranjero",
    "views.exploitation.domestic.desc": "Para los ciudadanos serbios",
    "views.exploitation.foreign.title": "Si es extranjero en Serbia",
    "views.exploitation.foreign.desc": "Para los ciudadanos extranjeros en Serbia",

    // Home
    "views.home.quiz.title": "Cuestionario",
    "views.home.quiz.description": "Obtenga más información sobre la trata de personas a través de un cuestionario",
    "views.home.quiz.play": "Iniciar el cuestionario",
    "views.home.news-activities-title": "Noticias y actividades",
    "views.home.more": "Más...",

    // Astra services
    "views.astra-services.title": "Los servicios que ofrece Astra",
    "views.astra-services.desc-short": "Obtenga más información sobre los servicios.",
    "views.astra-services.desc": "En esta página puede encontrar información sobre los servicios que ofrece Astra",

    // Quiz
    "views.quiz.title": "Cuestionario",
    "views.quiz.description": "Obtenga más información sobre la trata de personas a través de un cuestionario interactivo. Elija uno de los cuestionarios.",
    "views.quiz.play":"Iniciar el cuestionario",
    'views.quiz.stats.question': 'Pregunta',
    'views.quiz.stats.score': 'Puntos',
    'views.quiz.stats.time': 'Tiempo',
    'views.quiz.end.title': 'Juego terminado',
    'views.quiz.end.description': 'Gracias por participar en el cuestionario.',
    'views.quiz.end.correct-answers': 'Respuestas correctas',
    'views.quiz.end.incorrect-answers': 'Respuestas incorrectas',
    'views.quiz.end.question-summary': 'Preguntas',
    'views.quiz.timeout': 'Su tiempo se acabó',
    'views.quiz.action.next': 'Siguiente',
    'views.quiz.action.ok': 'Está bien',
    'views.quiz.action.skip': 'Saltarlo',
    'views.quiz.action.confirm': 'Confirmar',
    'views.quiz.info': 'Información',
    'views.quiz.right': 'Tiene razón.',
    'views.quiz.wrong': 'Desafortunadamente, está equivocado.',

    'views.back.exit.title': '¿Está seguro?',
    'views.back.exit.desc': '¿Está seguro de que quiere salir de la pantalla? Sus datos se perderán (cuestionario, encuesta).',

    // Anti trafficking
    "views.anti-trafficking.title": "La lucha contra la trata de personas",

    // Report problem
    "views.report-problem.intro.description": "Si se siente inseguro, asustado o no quiere compartir sus datos personales con nosotros, también puede escribirnos de forma anónima.",
    "views.report-problem.form.d.description": "Estimado/a señor/señora, gracias por su confianza. Por favor, deje su información de contacto en la solicitud y describa la situación que está reportando con más detalle. Espere que nos pongamos en contacto con usted lo antes posible.",
    "views.report-problem.form.a.description": "Estimado/a señor/señora, gracias por su confianza. Dada la especificidad de los informes anónimos, podremos tomar medidas según la cantidad de información que comparta con nosotros. Describa el problema con más detalle y, si es posible, la forma de contactar a la persona que está en riesgo potencial de convertirse en víctima de trata de personas.",
    "views.report-problem.action.data": "Iniciar sesión con datos personales",
    "views.report-problem.action.anonymous": "Inicio de sesión anónimo",
    "views.report-problem.end.placeholder.title": "¡Gracias!",
    "views.report-problem.end.placeholder.message": "Su mensaje ha sido enviado.",
    "views.report-problem.end.placeholder.back": "Atrás",
    'views.report-problem.form.label.name': "Nombre y apellido",
    'views.report-problem.form.label.phone': "Teléfono",
    'views.report-problem.form.label.mail': "Correo electrónico",
    'views.report-problem.form.label.title': "Título",
    'views.report-problem.form.label.message': "Мensaje",
    'views.report-problem.form.submit': "Enviar",
    'views.report-problem.validation.title': 'Por favor verifique los campos',
    'views.report-problem.validation.desc': 'Algunos campos están vacíos o no tienen el formato correcto. Por favor verifique todos los campos.',
    'views.report-problem.client.title': 'Error de cliente',
    'views.report-problem.client.desc': 'Sin red',
    'views.report-problem.server.title': 'Error de servidor',
    'views.report-problem.server.desc': 'Se produjo un error inesperado.',

    // Survey
    "views.survey.title": "Encuesta",
    "views.survey.description": "Para comprender mejor el fenómeno de la explotación laboral y planificar nuestras actividades futuras, sería de gran importancia para nosotros sus conocimientos y experiencias, que puede compartir con nosotros a través de algunas preguntas en una encuesta anónima.",
    "views.survey.start": "Iniciar una encuesta",
    'views.survey.exit.title': '¿Está seguro?',
    'views.survey.exit.desc': '¿Está seguro de que quiere salir de la encuesta?',
    "views.survey-welcome.title": "Bienvenido/a",
    "views.survey-welcome.placeholder.title": "Iniciar una encuesta",
    "views.survey.placeholder.content": "Maecenas sit amet sollicitudin dolor, quis fringilla tortor, quis fringilla tortor.",
    "views.survey.placeholder.button.content": "Continuar",
    "views.survey.button.next": "Siguiente",
    "views.survey.button.previous": "Atrás",
    "views.survey.end-screen.title": "¡Gracias!",
    "views.survey.end-screen.content": "Estos datos se utilizarán con el fin de planificar actividades para luchar contra la explotación laboral y desarrollar servicios prestados a (potenciales) víctimas de la trata de personas.",
    "views.survey.end-screen.button.content": "Atrás",

    // Post
    'views.post.title': 'Noticias',

    // Resource placeholder
    'placeholder.network.title': 'Error en línea',
    'placeholder.network.message': 'Sin datos. Por favor verifique su conexión a Internet e inténtelo de nuevo.',
    'placeholder.network.action': 'Inténtelo de nuevo',

    // Menu
    "menu.home": "Inicio",
    "menu.anti-trafficking": "Trata de personas",
    "menu.eol": "Explotación laboral",
    "menu.sos": "SOS",
    "menu.quiz": "Cuestionario",
    "menu.survey": "Encuesta",
    "menu.report-problem": "Informar un problema",
    "menu.exit": "Salir",

    // Misc
    "cancel": "Cancelar",
    "confirm": "Confirmar",
    "ok": "Está bien",
    "yes": "Sí",
    "no": "No",
    "translate": "Traducir",
    "available-translations": "Traducciones disponibles",
    "auto-translate": "Traducción automática",
    "share-news": "Compartir esta noticia",
    "share-app": "Compartir la aplicación",
    "share.try-astra-app": "Pruebe la aplicación Astra",
    "music": "Música",
    "privacy-policy": "Politica de Privacidad",

    "dialog.survey.title": "¡Nueva encuesta!",
    "dialog.survey.message": "Una nueva encuesta está disponible en la aplicación. Ayúdenos en la misión. ¡Complete la encuesta!",
    "dialog.survey.later": "Después",
    "dialog.survey.go": "¡Inicie la encuesta!",

    "dialog.share-app.title": "Comparta la aplicación Astra con otros.",

    // Level Stats
    "level-stats.current-level": "Su nivel actual",
    
    "level-stats.level.1.title": "Chispa de Esperanza",
    "level-stats.level.1.desc" : "¡Usted es la \"Chispa de la Esperanza\" ahora mismo! Continúe su viaje hacia la concientización sobre la trata de personas a través de nuestra plataforma educativa.",
    "level-stats.level.2.title": "Viajero de la Justicia",
    "level-stats.level.2.desc": "¡Su apoyo es crucial! Usted contribuyó con su compromiso y sensibilización. ¡Continúe su progreso!",
    "level-stats.level.3.title": "Voz de la Libertad",
    "level-stats.level.3.desc": 'Como actual "Voz de la Libertad", su compromiso con la lucha contra la trata de personas inspira a otros. ¡Siga estableciendo los estándares para el cambio!',

    "level-stats.upgraded.title": "¡Felicidades!",
    "level-stats.upgraded.desc": "¡Ha avanzado al siguiente nivel!",

    "level-stats.more": "¿Cómo puedo avanzar?",

    // Levels
    "views.levels.title": "Niveles",
    "views.levels.page-title": "Actívese y contribuya a la lucha común contra la trata de personas",
    "views.levels.page-desc": "Complete tareas para convertirse en un verdadero luchador contra la trata de personas. Infórmese, participe en cuestionarios y encuestas y comparta la aplicación con sus amigos.",
    
    "views.levels.quiz.title": "Juego de preguntas",
    "views.levels.quiz.desc": "Participe en el cuestionario, pruebe sus conocimientos sobre la trata de personas y podrá ganar [n] puntos.",

    "views.levels.share.title": "Compartir la aplicación",
    "views.levels.share.desc": "Comparta la aplicación con sus amigos y gane [n] puntos.",

    "views.levels.survey.title": "Encuesta",
    "views.levels.survey.desc": "Complete la encuesta y gane [n] puntos.",

}