<template>
    <background>
        <view-header :title="lang('menu.report-problem')"></view-header>
        <div class="view p-lr">
            <div class="page-intro">
                <p>{{lang('views.report-problem.intro.description')}}</p>
            </div>
            <div class="items">
                <router-link :to="{path: '/report/form'}" class="item">
                    <c-square-button icon="user"></c-square-button>
                    <p>{{lang('views.report-problem.action.data')}}</p>
                </router-link>
                <div class="vertical-line"></div>
                <router-link :to="{path: '/report/form', query: {anonymous: true}}" class="item">
                    <c-square-button icon="user-x"></c-square-button>
                    <p>{{lang('views.report-problem.action.anonymous')}}</p>
                </router-link>
            </div>
        </div>
    </background>
</template>





<script>


</script>



<style lang="scss" scoped>

    @import "../scss/includes.scss";


    .items{
        display: flex;
        -webkit-tap-highlight-color: transparent;
        justify-content: center;
        text-align: center;


        p{
            padding-left: 25px;
            padding-right: 25px;
            width: 100px;
            text-transform: uppercase;
            font-size: 0.80em;
            font-weight: 500;
            margin-top: 15px;
            align-items: center;
            text-align: center;
            line-height: 15px;
        }

        .vertical-line{
            border-left: 1px solid white;
            height: 170px;
            margin-top: -20px;
            opacity: 0.1;
        }

    }




</style>
