<template>
    <background>
        <div class="flex-layout">
            <div class="view">
                <div class="wrap">
                    <div class="owl-carousel slider" ref="slider">
                        <div class="item slider-item" v-for="slide in 4" :key="slide">
                            <div class="padding">
                                <img class="clipart" :src="require('../assets/img/intro-' + slide + '.png')">
                                <div class="heading">{{ lang('views.intro.' + slide + '.title') }}</div>
                                <p>{{ lang('views.intro.' + slide + '.description') }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="button-container">
                        <c-button @click="goTo(slideNum+1)" v-if="slideNum != 3">{{ lang('views.intro.button.next') }}
                        </c-button>
                        <template class="buttons" v-else>

                            <c-button :ghost="true" @click="goHome">{{ lang('views.intro.button.survey.skip') }}
                            </c-button>
                            <c-button @click="goToSurvey">{{ lang('views.intro.button.survey') }}
                            </c-button>
                        </template>
                    </div>
                    <div class="dots">
                        <button @click="goTo(0)" :class="{active: slideNum == '0'}"></button>
                        <button @click="goTo(1)" :class="{active: slideNum == '1'}"></button>
                        <button @click="goTo(2)" :class="{active: slideNum == '2'}"></button>
                        <button @click="goTo(3)" :class="{active: slideNum == '3'}"></button>
                    </div>
                    <div class="links">
                        <a href="https://astra.rs/" target="_blank">
                            <span class="privacy" >www.astra.rs</span> | 
                        </a> 
                        <a href="https://astra.rs/politika-privatnosti/" target="_blank">
                            <span class="privacy" >{{lang("privacy-policy")}}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </background>
</template>


<script>

export default {
    name: 'App',

    data() {
        return {
            slideNum: 0
        }
    },
    methods: {

        goTo(n) {

            this.owl.trigger('to.owl.carousel', n)

        },
        async goToSurvey() {

            this.$store.commit('setTransitionsActive', false)

            await this.$router.replace({path: '/'})

            await this.$router.push('/survey-welcome')

            setTimeout(() => {
                this.$store.commit('setTransitionsActive', true)
            }, 2000)

        },

        goHome() {

            this.$router.replace({path: '/'})

        },

        initializeOwl() {

            this.owl = $(this.$refs.slider).owlCarousel({
                items: 1,
            })

            this.owl.on('changed.owl.carousel', (event) => {
                this.slideNum = event.item.index;
            })

        }

    },

    mounted() {

        this.initializeOwl()

    }
};

</script>


<style lang="scss" scoped>


    .view {
        justify-content: center;
        align-items: center;
        display: flex;
        position: relative;
    }

    .wrap{
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    .slider {

        .slider-item {

            padding: 0 40px;

            img {
                width: 100%;
                height: 250px;
                object-fit: contain;
                object-position: center;
                border-radius: 50%;
                display: flex;
                margin-bottom: 20px;
            }

        }

        .heading {
            font-size: 1.2em;
            font-weight: 600;
            margin-bottom: 20px;
            text-align: center;
        }

        p {

            max-width: 470px;
            font-size: 0.9em;
            text-align: center;
            line-height: 20px;
            opacity: 0.5;
            height: 125px;

            margin: 0 auto;

        }
    }

    .dots {

        text-align: center;
        justify-content: center;

        button {
            background-color: white;
            opacity: 0.3;
            width: 12px;
            max-width: 12px;
            height: 12px;
            border-radius: 50%;
            text-decoration: none;
            border: none;
            margin-right: 5px;
            margin-left: 5px;
            display: inline-block;
            padding: 0;


            &.active {
                opacity: 1;

            }
        }
    }

    .button-container {
        margin: 0 7px;
        align-items: center;
        justify-content: center;
        display: flex;
        padding-top: 0px;
        padding-bottom: 10px;

        .button {
            padding-bottom: 15px;
            margin: 0 7.5px;
        }


    }


    .skip {
        background: none !important;
    }

    .links{
        text-align: center;
        margin-top: 40px;

        a{
            font-size: 0.9em;
            text-align: center;
            opacity: 0.3;
            height: 100px;
        }
    }


</style>
