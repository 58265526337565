<template>
    <div class="badge">
        <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="20.562mm" height="20.5672mm" version="1.1"
            style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
            viewBox="0 0 574.01 574.15" xmlns:xlink="http://www.w3.org/1999/xlink"
    >
            <g id="Layer_x0020_1">
                <path class="fil0"
                    :fill="badgeColor"
                    d="M212.93 573.47c16.08,3.71 28.69,-8.61 41.4,-16.12 8.6,-4.9 16.23,-12.17 26.17,-14.25 7.47,-1.46 15.47,-0.37 22.11,3.43 12.56,7.76 24.79,16.1 37.44,23.75 14.46,8.08 34.56,2.62 42.82,-11.78 6.91,-12.8 13.39,-25.86 20.23,-38.69 5.61,-9.98 17,-16.25 28.42,-15.86 9.89,0.42 19.79,0.83 29.69,1.37 6.86,0.16 13.89,1.38 20.62,-0.63 14.01,-3.47 24.34,-17.37 23.99,-31.75 -0.44,-14.18 -1.49,-28.37 -1.83,-42.56 -0.02,-11.64 6.97,-22.96 17.37,-28.18 7.62,-3.93 15.25,-7.86 22.87,-11.8 7.03,-4 15.1,-6.66 20.75,-12.69 9.9,-10.09 11.99,-26.85 4.61,-38.95 -7.34,-11.71 -14.88,-23.25 -22.3,-34.91 -6.18,-9.77 -6.36,-23 -0.46,-32.95 7.51,-12.14 15.42,-24.01 22.96,-36.13 8.72,-14.57 3.36,-35.24 -11.31,-43.76 -12.41,-6.75 -25.14,-12.89 -37.58,-19.55 -6.66,-3.47 -11.95,-9.46 -14.64,-16.45 -4.02,-9.7 -1.4,-20.38 -1.49,-30.52 -0.06,-13.76 4.18,-29.12 -4.11,-41.38 -6.95,-11.43 -21.06,-16.56 -33.97,-14.63 -14.72,-0.09 -29.67,3.12 -44.22,0.54 -8.24,-2.19 -15.53,-7.8 -19.66,-15.29 -6.86,-12.89 -13.18,-26.04 -20.27,-38.8 -8.65,-14.2 -28.8,-19.14 -43.12,-10.81 -12.14,7.52 -24.03,15.43 -36.13,23.03 -9.4,5.68 -21.72,5.94 -31.37,0.72 -12.54,-7.71 -24.75,-15.95 -37.27,-23.7 -14.37,-8.52 -34.69,-3.48 -43.34,10.86 -7.04,12.76 -13.46,25.87 -20.36,38.71 -7.47,13.76 -25.25,19.21 -39.59,14.75 -12.43,0.64 -24.86,-1.09 -37.27,-0.13 -14.42,2.91 -26.17,17.13 -25.76,32.07 0.02,14.85 1.57,29.66 1.75,44.48 -1.18,9.44 -5.85,18.63 -13.65,24.27 -13.57,7.56 -27.59,14.35 -41.16,21.93 -14.35,8.72 -19.69,29.04 -11.16,43.56 7.53,12.15 15.44,24.05 23.04,36.15 5.29,8.85 5.88,20.27 1.47,29.58 -7.76,13.55 -16.89,26.28 -24.88,39.7 -7.89,14.24 -2.88,33.91 11.07,42.42 12.78,7.03 25.91,13.39 38.78,20.25 9.92,5.4 16.45,16.4 16.52,27.68 -0.42,14.79 -1.66,29.56 -1.83,44.35 0.13,16.43 14.88,30.96 31.3,30.98 14.83,-0.3 29.63,-1.47 44.48,-1.85 11.32,0.22 22.24,6.97 27.51,17.02 6.31,12.14 12.5,24.32 18.85,36.44 4.56,8.41 13.08,14.47 22.5,16.08z" />
            </g>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="20.562mm" height="20.5672mm" version="1.1"
            style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
            viewBox="0 0 574.01 574.15" xmlns:xlink="http://www.w3.org/1999/xlink"
    >
            <g id="Layer_x0020_1">
                <path class="fil0"
                    :fill="badgeColor"
                    d="M212.93 573.47c16.08,3.71 28.69,-8.61 41.4,-16.12 8.6,-4.9 16.23,-12.17 26.17,-14.25 7.47,-1.46 15.47,-0.37 22.11,3.43 12.56,7.76 24.79,16.1 37.44,23.75 14.46,8.08 34.56,2.62 42.82,-11.78 6.91,-12.8 13.39,-25.86 20.23,-38.69 5.61,-9.98 17,-16.25 28.42,-15.86 9.89,0.42 19.79,0.83 29.69,1.37 6.86,0.16 13.89,1.38 20.62,-0.63 14.01,-3.47 24.34,-17.37 23.99,-31.75 -0.44,-14.18 -1.49,-28.37 -1.83,-42.56 -0.02,-11.64 6.97,-22.96 17.37,-28.18 7.62,-3.93 15.25,-7.86 22.87,-11.8 7.03,-4 15.1,-6.66 20.75,-12.69 9.9,-10.09 11.99,-26.85 4.61,-38.95 -7.34,-11.71 -14.88,-23.25 -22.3,-34.91 -6.18,-9.77 -6.36,-23 -0.46,-32.95 7.51,-12.14 15.42,-24.01 22.96,-36.13 8.72,-14.57 3.36,-35.24 -11.31,-43.76 -12.41,-6.75 -25.14,-12.89 -37.58,-19.55 -6.66,-3.47 -11.95,-9.46 -14.64,-16.45 -4.02,-9.7 -1.4,-20.38 -1.49,-30.52 -0.06,-13.76 4.18,-29.12 -4.11,-41.38 -6.95,-11.43 -21.06,-16.56 -33.97,-14.63 -14.72,-0.09 -29.67,3.12 -44.22,0.54 -8.24,-2.19 -15.53,-7.8 -19.66,-15.29 -6.86,-12.89 -13.18,-26.04 -20.27,-38.8 -8.65,-14.2 -28.8,-19.14 -43.12,-10.81 -12.14,7.52 -24.03,15.43 -36.13,23.03 -9.4,5.68 -21.72,5.94 -31.37,0.72 -12.54,-7.71 -24.75,-15.95 -37.27,-23.7 -14.37,-8.52 -34.69,-3.48 -43.34,10.86 -7.04,12.76 -13.46,25.87 -20.36,38.71 -7.47,13.76 -25.25,19.21 -39.59,14.75 -12.43,0.64 -24.86,-1.09 -37.27,-0.13 -14.42,2.91 -26.17,17.13 -25.76,32.07 0.02,14.85 1.57,29.66 1.75,44.48 -1.18,9.44 -5.85,18.63 -13.65,24.27 -13.57,7.56 -27.59,14.35 -41.16,21.93 -14.35,8.72 -19.69,29.04 -11.16,43.56 7.53,12.15 15.44,24.05 23.04,36.15 5.29,8.85 5.88,20.27 1.47,29.58 -7.76,13.55 -16.89,26.28 -24.88,39.7 -7.89,14.24 -2.88,33.91 11.07,42.42 12.78,7.03 25.91,13.39 38.78,20.25 9.92,5.4 16.45,16.4 16.52,27.68 -0.42,14.79 -1.66,29.56 -1.83,44.35 0.13,16.43 14.88,30.96 31.3,30.98 14.83,-0.3 29.63,-1.47 44.48,-1.85 11.32,0.22 22.24,6.97 27.51,17.02 6.31,12.14 12.5,24.32 18.85,36.44 4.56,8.41 13.08,14.47 22.5,16.08z" />
            </g>
        </svg>
        <div class="icon-c">
            <icon icon="astra"></icon>
        </div>
    </div>
</template>

<script>


export default {

    props: {
        color: {
            required: true,
            type: String
        }
    },

    computed: {

        badgeColor(){
            return this.color
        }

    },

}



</script>

<style lang="scss" scoped>

.badge {

    position: relative;

    svg{
        width: 100%;
        height: 100%;
        display: block;
    }

    svg:nth-child(2) {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        // blur
        filter: blur(7px);
    }

    .icon-c {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        

    }

}

</style>
