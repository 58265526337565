export default {

    "views.language.title": "Odabir jezika",
    "views.language.confirm": "Potvrdi",

    // Intro
    "views.intro.1.title": "Dobro došli",
    "views.intro.2.title": "Borba protiv trgovine ljudima",
    "views.intro.3.title": "Saznajte više o trgovini ljudima",
    "views.intro.4.title": "Popunite anketu",
    "views.intro.1.description": "ASTRA je nevladina oganizacija posvećena iskorenjivanju svih oblika trgovine ljudima i eksploatacije",
    "views.intro.2.description": "Borba protiv trgovine ljudima mora biti sveobuhvatna što podrazumeva delovanje na polju prevencije, zaštite i  podrške žrtvama kao i gonjenje počinilaca.",
    "views.intro.3.description": "Ova aplikacija će vam dati osnovne informacije o trgovini ljudima i uslovima zaposlenja u Srbiji i inostranstvu,  kako da se zaštitite kao i korisne kontakte u zemlji i inostranstvu.",
    "views.intro.4.description": "U cilju što efikasnijeg delovanja ASTRA sprovodi dobrovoljnu anketu. Učestvujte zajedno sa nama u suzbijanju trgovine ljudima",
    "views.intro.button.next": "Dalje",
    "views.intro.button.survey": "Anketa",
    "views.intro.button.survey.skip": "Preskoči",
    "views.intro.button.start.survey": "Započni anketu",
    "views.intro.button.back.survey": "Nazad",
    "views.intro.button.start.game": "Započni igru",

    // SOS
    "views.sos.description": "Ukoliko su Vam potrebne dodatne preventivne informacije, imate dileme, prepoznajete neki oblik trgovine ljudima u svom okruženju ili Vam je potrebna pomoć i podrška, možete nas kontaktirati",
    "views.sos.report-problem.title": "Prijavi problem",
    "views.sos.report-problem.message": "Kontaktirajte nas putem forme",
    "views.sos.phone.1.message": "Dostupno 24/7",
    "views.sos.phone.2.message": "Dostupno 24/7 (besplatan sa teritorije Srbije)",
    "views.sos.fb.title": "Facebook/Messenger",
    "views.sos.fb.message": "Zvanična facebook strana",
    "views.sos.mail.title": "E-Mail",
    "views.sos.mail.message": "Kontaktirajte nas putem E-mail-a (sos@astra.rs)",

    //Country residence
    "views.eol.country.title": "Odabir zemlje",
    "views.eol.country.description": "Ukoliko razmatrate da posao potražite u inostranstvu, pripremili smo za vas korisne informacije o uslovima zaposlenja u nekim od evropskih zemalja. ",

    //Exploitation
    "views.exploitation.description":"Prikupi informacije i informiši se o svojim pravima pre nego što doneseš odluku o radnom angažovanju.",
    "views.exploitation.general.title": "Korisne informacije",
    "views.exploitation.general.desc": "Korisne informacije o radnoj eksploataciji",
    "views.exploitation.domestic.title": "Ako ste u inostranstvu",
    "views.exploitation.domestic.desc": "Za srpske državljane",
    "views.exploitation.foreign.title": "Ako ste stranac u Srbiji",
    "views.exploitation.foreign.desc": "Za strane državljane u Srbiji",

    // Home
    "views.home.quiz.title": "Kviz",
    "views.home.quiz.description": "Nauči više o trgovini ljudima kroz kviz ",
    "views.home.quiz.play": "Započni kviz",
    "views.home.news-activities-title": "Vesti i aktivnosti",
    "views.home.more": "Više...",

    // Astra services
    "views.astra-services.title": "Usluge koje Astra pruža",
    "views.astra-services.desc-short": "Informišite se više o uslugama",
    "views.astra-services.desc": "Na ovoj strani možete pronaći informacije o uslugama koje Astra pruža",

    // Quiz
    "views.quiz.title": "Kviz",
    "views.quiz.description": "Nauči više o trgovini ljudima kroz interaktivan kviz. Odaberi jedan od kvizova.",
    "views.quiz.play":"Započni kviz",
    'views.quiz.stats.question': 'Pitanje',
    'views.quiz.stats.score': 'Poeni',
    'views.quiz.stats.time': 'Vreme',
    'views.quiz.end.title': 'Kraj igre',
    'views.quiz.end.description': 'Hvala vam što ste učestvovali u kvizu.',
    'views.quiz.end.correct-answers': 'Tačni odgovori',
    'views.quiz.end.incorrect-answers': 'Netačni odgovori',
    'views.quiz.end.question-summary': 'Pitanja',
    'views.quiz.timeout': 'Vaše vreme je isteklo',
    'views.quiz.action.next': 'Dalje',
    'views.quiz.action.ok': 'U redu',
    'views.quiz.action.skip': 'Preskoči',
    'views.quiz.action.confirm': 'Potvrdi',
    'views.quiz.info': 'Info',
    'views.quiz.right': 'U pravu ste.',
    'views.quiz.wrong': 'Nažalost, pogrešili ste. ',

    'views.back.exit.title': 'Da li ste sigurni?',
    'views.back.exit.desc': 'Da li ste sigurni da želite da napustite ekran? Vaš podaci će biti izgubljeni (kviz, anketa).',

    // Anti trafficking
    "views.anti-trafficking.title": "Borba protiv trgovine ljudima",

    // Report problem
    "views.report-problem.intro.description": "Ukoliko se osećate nesigurno, uplašeni ste ili ne želite da delite sa nama Vaše lične podatke možete nam pisati i anonimno. ",
    "views.report-problem.form.d.description": "Poštovani/a, hvala vam na poverenju. Molimo vas da u prijavi ostavite vaše kontakt podatke i što detaljnije opišete situaciju koju prijavljujete. Očekujte da vas kontaktiramo u što kraćem roku. ",
    "views.report-problem.form.a.description": "Poštovani/a, hvala vam na poverenju. Uzimajući u obzir specifičnost anonimne prijave, bićemo u mogućnosti da preduzimamo radnje u odnosu na količinu informacija koje podelite sa nama. Molimo vas da što detaljnije opišeti problem i po mogućstvu način kontaktiranja sa osobom koja je u potencijalnom riziku da postane žrtva trgovine ljudima.",
    "views.report-problem.action.data": "Prijava sa ličnim podacima",
    "views.report-problem.action.anonymous": "Anonimna prijava",
    "views.report-problem.end.placeholder.title": "Hvala Vam!",
    "views.report-problem.end.placeholder.message": "Vaša poruka je poslata.",
    "views.report-problem.end.placeholder.back": "Nazad",
    'views.report-problem.form.label.name': "Ime i prezime",
    'views.report-problem.form.label.phone': "Kontakt telefon",
    'views.report-problem.form.label.mail': "E-mail",
    'views.report-problem.form.label.title': "Naslov",
    'views.report-problem.form.label.message': "Poruka",
    'views.report-problem.form.submit': "Pošalji",
    'views.report-problem.validation.title': 'Molimo proverite polja',
    'views.report-problem.validation.desc': 'Neka polja su prazna ili nisu ispravnog formata. Molimo proverte sva polja.',
    'views.report-problem.client.title': 'Klijentska greška',
    'views.report-problem.client.desc': 'Nema mreže',
    'views.report-problem.server.title': 'Serverska greška',
    'views.report-problem.server.desc': 'Došlo je do neočekivane greške',

    // Survey
    "views.survey.title": "Anketa",
    "views.survey.description": "Kako bismo stekli bolji uvid u fenomen radne eksploatacije i planirali naše buduće aktivnosti od velikog značaja bi nam bila vaša saznanja i iskustva koja možete podeliti sa nama kroz par pitanja u anonimnoj anketi",
    "views.survey.start": "Započni anketu",
    'views.survey.exit.title': 'Da li ste sigurni?',
    'views.survey.exit.desc': 'Da li ste sigurni da želite da napustite anketu?',
    "views.survey-welcome.title": "Dobro došli",
    "views.survey-welcome.placeholder.title": "Započni anketu",
    "views.survey.placeholder.content": "Maecenas sit amet sollicitudin dolor, quis fringilla tortor, quis fringilla tortor.",
    "views.survey.placeholder.button.content": "Nastavi",
    "views.survey.button.next": "Dalje",
    "views.survey.button.previous": "Nazad",
    "views.survey.end-screen.title": "Hvala Vam!",
    "views.survey.end-screen.content": "Ovi podaci će biti korišćeni u svrhu planiranja aktivnosti u suzbijanju radne eksploatacije i razvoja usluga koje su pružaju (potencijalnim) žrtvama trgovine ljudima.",
    "views.survey.end-screen.button.content": "Nazad",

    // Post
    'views.post.title': 'Vesti',

    // Resource placeholder
    'placeholder.network.title': 'Greška na mreži',
    'placeholder.network.message': 'Nema podataka. Molimo proverite vašu internet konekciju i pokušajte ponovo.',
    'placeholder.network.action': 'Pokušaj ponovo',

    // Menu
    "menu.home": "Početak",
    "menu.anti-trafficking": "Trgovina ljudima",
    "menu.eol": "Radna eksploatacija",
    "menu.sos": "SOS",
    "menu.quiz": "Kviz",
    "menu.survey": "Anketa",
    "menu.report-problem": "Prijavi problem",
    "menu.exit": "Izlaz",

    // Misc
    "cancel": "Odustani",
    "confirm": "Potvrdi",
    "ok": "U Redu",
    "yes": "Da",
    "no": "Ne",
    "translate": "Prevedi",
    "available-translations": "Dostupni prevodi",
    "auto-translate": "Automatski prevod",
    "share-news": "Podeli ovu vest",
    "share-app": "Podeli aplikaciju",
    "share.try-astra-app": "Probaj Astra aplikaciju",
    "music": "Muzika",
    "privacy-policy": "Politika privatnosti",

    "dialog.survey.title": "Nova anketa!",
    "dialog.survey.message": "Nova anketa dostupna je u aplikaciji. Pomozite nam u misiji. Popunite anketu!",
    "dialog.survey.later": "Kasnije",
    "dialog.survey.go": "Započni anketu!",

    "dialog.share-app.title": "Podeli Astra aplikaciju sa drugima",

    // Level Stats
    "level-stats.current-level": "Vaš trenutni nivo",
    
    "level-stats.level.1.title": "Iskra Nade",
    "level-stats.level.1.desc" : "Trenutno si \"Iskra Nade\"! Nastavi svoje putovanje ka osvešćivanju o trgovini ljudima kroz našu edukativnu platformu.",
    "level-stats.level.2.title" : "Putnik Pravde",
    "level-stats.level.2.desc": "Tvoja podrška je ključna! Doprineo/la si svojim angažmanom i podizanjem svesti. Nastavi svoj napredak!",
    "level-stats.level.3.title": "Glas Slobode",
    "level-stats.level.3.desc": "Kao trenutni \"Glas Slobode\", tvoja predanost borbi protiv trgovine ljudima inspiriše druge. Nastavi da postavljaš standarde promene!",

    "level-stats.upgraded.title": "Čestitamo!",
    "level-stats.upgraded.desc": "Napredovli ste u sledeći nivo!",

    "level-stats.more": "Kako da napredujem?",

    // Levels
    "views.levels.title": "Nivoi",
    "views.levels.page-title": "Aktiviraj se i doprinesi zajedničkoj borbi protiv trgovine ljudima",
    "views.levels.page-desc": "Ispunite zadatke kako biste postali pravi borac protiv trgovine ljudima. Informišite se, učestvujte u kvizu i anketi i podelite aplikaciju sa svojim prijateljima.",
    
    "views.levels.quiz.title": "Kviz igra",
    "views.levels.quiz.desc": "Učestvuj u kvizu, proveri svoje znanje o trgovini ljudima i možeš osvojiti [n] poena.",

    "views.levels.share.title": "Podeli aplikaciju",
    "views.levels.share.desc": "Podeli aplikaciju sa svojim prijateljima i osvoji [n] poena.",

    "views.levels.survey.title": "Anketa",
    "views.levels.survey.desc": "Popuni anketu i osvoji [n] poena.",

}
