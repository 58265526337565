import store from './store'
import settings from './settings'
import lang from './lang'
import ResourceManager from './ResourceManager'
import HTTPManager from './HTTPManager'
import localforage from 'localforage';
import helpers from "./helpers";
import { FCM } from "@capacitor-community/fcm";
import { PushNotifications } from "@capacitor/push-notifications";

export default {

    httpManager: new HTTPManager(),
    resourceManager: new ResourceManager(store),

    /**
     * Register resources
     */
    registerResources() {

        // Define resources
        for (let i = 0; i < lang.availableLanguages.length; i++) {
            this.resourceManager.setupResource('posts:' + lang.availableLanguages[i].key, settings.wpWebsiteUrl + '/wp-json/wp/v2/posts?_embed&lang=' + lang.availableLanguages[i].key)
        }

        this.resourceManager.setupResource('quizAndSurvey', settings.serverBaseUri + '/api/quiz-and-survey',  {
            params: {
                populate: [
                    'activeQuizzes',
                    'activeQuizzes.quizzes.questions.answers',
                    'activeQuizzes.quizzes.localizations.questions.answers',
                    'activeSurvey',
                    'activeSurvey.survey.Chyron',
                    'activeSurvey.survey.localizations.Chyron',
                    'activeSurvey.survey.Questions.answers',
                    'activeSurvey.survey.localizations.Questions.answers'
                ]
            }
        });

        // Anti trafficking
        this.resourceManager.setupResource('antiTrafficking', settings.serverBaseUri + '/api/anti-trafficking',  {
            params: {
                populate: [
                    'posts',
                    'posts.posts',
                    'posts.posts.content',
                    'posts.posts.localizations.content',
                ]
            }
        });

        // Anti trafficking
        this.resourceManager.setupResource('astraServices', settings.serverBaseUri + '/api/astra-service',  {
            params: {
                populate: [
                    'posts',
                    'posts.posts',
                    'posts.posts.content',
                    'posts.posts.localizations.content',
                ]
            }
        });

        // Exploatation of labour
        this.resourceManager.setupResource('eol', settings.serverBaseUri + '/api/exploitation-of-labour',  {
            params: {
                populate: [
                    'EOLFPosts.posts.content.links',
                    'EOLFPosts.posts.localizations.content.links',
                    'EOLFPosts.posts.thumbnail',
                    'EOLFPosts.posts.image',
                    'EOLGiPosts.posts.content.links',
                    'EOLGiPosts.posts.localizations.content.links',
                    'EOLSPost.post.content.links',
                    'EOLSPost.post.localizations.content.links',
                ]
            }
        });

        // All posts
        this.resourceManager.setupResource('posts', settings.serverBaseUri + '/api/posts',  {
            params: {
                populate: [
                    'content.links',
                    'localizations.content.links',
                    'content.posts',
                    'localizations.content.posts',
                ]
            }
        });

    },

    /**
     * Initialize App - Read user configuration and so on
     * @returns {Promise}
     */
    async initialize() {

        // Setup FCM, but do not wait for response
        this.setupFCM()
        this.setTestingFCMSubscription(false)

        this.registerResources();

        // First load config
        try {
            await this.loadConfig()
        } catch (e) {
            // Do nothing, still allow user to see app
            console.log('Failed to load configuration: ', e)
        }

        // Load resource from local database
        try {
            await this.resourceManager.loadResourcesLocally()
        } catch (e) {
            // Do nothing, still allow user to see app
            console.log('Failed to load resources locally: ', e)
        }

        let appLoadedFirstTime = false;

        if (!this.getConfig('resourcesLoadedOnce')) {

            // Load resources from remote
            try {

                await this.resourceManager.loadResourcesRemote()
                this.setConfig("resourcesLoadedOnce", true)
                
                appLoadedFirstTime = true;

            } catch (e) {
                // Do nothing, still allow user to see app
                console.log('Failed to load resources remote: ', e)
            }

        } else {

            // This is not first time app is loaded, so we wont wait for data
            this.resourceManager.loadResourcesRemote()

        }


        this.loadMusic()

        if(!appLoadedFirstTime)
            this.setupSurveyDialogInterval()

        return Promise.resolve()

    },

    /**
     * 
     */
    setupSurveyDialogInterval(){

        setInterval(() => {

            if(
                this.isActiveSurveyAvailableForUser()
            ){

                let survey = this.activeSurvey()

                if(!survey)
                    return false;

                if(!this.isSurveyDialogShown(survey.id)){
                    helpers.surveyDialog()
                    this.markSurveyDialogShown(survey.id)
                }

            }


        }, 5 * 1000)

    },

    /**
     *
     * @returns {Promise}
     */
    loadConfig() {

        return new Promise((resolve, reject) => {

            localforage.getItem('config').then(function (value) {

                if (value)
                    store.commit('setConfig', value)

                resolve();

            }).catch(reject)

        })

    },

    /**
     *
     * @param key
     * @param value
     */
    setConfig(key, value) {

        let cfg = store.getters.config;

        cfg[key] = value;

        store.commit('setConfig', cfg)

        this.saveConfig()

    },

    /**
     *
     * @param key
     * @returns {Promise}
     */
    getConfig(key) {

        return store.getters.config[key]

    },


    /**
     *
     */
    saveConfig() {

        return new Promise((resolve, reject) => {

            localforage.setItem('config', JSON.parse(JSON.stringify(store.getters.config)))
                .then(resolve)
                .catch(reject)

        })

    },


    /**
     *
     * @returns {Promise<unknown[]>}
     */
    preloadImageResources(){

        let images = [
            require('../src/assets/img/background.png'),
            require('../src/assets/img/background-blurred.png'),
            require('../src/assets/img/logo.svg'),
            require('../src/assets/img/flags/rs.png'),
            require('../src/assets/img/intro-1.png'),
            require('../src/assets/img/intro-2.png'),
            require('../src/assets/img/intro-3.png'),
            require('../src/assets/img/intro-4.png'),
            require('../src/assets/img/logo-icon.svg'),
            require('../src/assets/img/shield.png'),
        ];

        return helpers.preloadImages(images);

    },


    /**
     * 
     */
    loadMusic(){

        this.musicAudio = new Audio('/sounds/music.mp3');
        this.musicAudio.loop = true;
        this.musicAudio.volume = 0.3;

    },


    /**
     * 
     * @param {*} status 
     */
    setMusic( status ){

        if(!this.musicAudio)
            return false;

        if(status){
            this.musicAudio.play()
        }
        else{
            this.musicAudio.pause()
        }

    },


    /**
     * 
     * @param {*} status 
     * @returns 
     */
     musicStatus( status ){

        if(!this.musicAudio)
            return false;

        return !this.musicAudio.paused

    },

    /**
     * 
     * @returns 
     */
    activeSurvey(){

        let survey = this.resourceManager.getResource('quizAndSurvey')

        if(!survey)
            return null;

        return survey.data.data.attributes.activeSurvey.survey.data

    },

    /**
     * 
     * @returns 
     */
    isSurveySubmitted( id ){

        return !!this.getConfig("survey." + id + ".submitted")

    },

    /**
     * 
     * @returns 
     */
    isAnySurveySubmitted(){

        return !!this.getConfig("survey.any.submitted")

    },

    /**
     * 
     * @returns 
     */
    markSurveySubmitted( id ){

        this.setConfig("survey." + id + ".submitted", new Date())

        if(!this.isAnySurveySubmitted())
            this.setConfig("survey.any.submitted", new Date())

    },
    /**
     * 
     * @returns 
     */
    isSurveyDialogShown( id ){

        return !!this.getConfig("survey." + id + ".dialog")

    },

    /**
     * 
     * @returns 
     */
    markSurveyDialogShown( id ){

        return this.setConfig("survey." + id + ".dialog", new Date())

    },

    /**
     * 
     * @param {*} status 
     * @returns 
     */
    isActiveSurveyAvailableForUser(){

        let survey = this.activeSurvey()

        if(!survey)
            return false;

        return !this.isSurveySubmitted( survey.id );

    },

    /**
     * 
     */
    setLanguage(newValue){

        this.setConfig('lang', newValue)
        
        if(window.FirebasePlugin) 
            FirebasePlugin.setUserProperty("lang", this.getConfig('lang'));

    },

    /**
     * 
     */
    async setupFCM(){

        try{

            await PushNotifications.requestPermissions();
            await PushNotifications.register();

            await FCM.subscribeTo({ topic: "astra.main"})
            await FCM.subscribeTo({ topic: "astra.main.lang-" + this.getConfig('lang') })

            await FCM.getToken()
            await FCM.setAutoInit({ enabled: true })

        }
        catch(e) {

            console.log("Failed to setup FCM", e);

        }

    },

    /**
     * 
     */
    async setTestingFCMSubscription( state = true ){

        try{

            if(state){
                await FCM.subscribeTo({ topic: "astra.test"})
            }
            else{
                await FCM.unsubscribeFrom({ topic: "astra.test"})
            }

        }
        catch(e) {

            console.log("Failed to setup Test FCM", e);

        }

    }


}














