<template>
    <div class="post-content" v-html="content" ref="content">
    </div>
</template>

<script>

    export default {

        props: {
            content: {
                type: String,
                default: '',
            }
        },

        mounted() {

            this.$refs.content.querySelectorAll('a').forEach((a) => {

                a.addEventListener('click', (e) => {

                    e.preventDefault();
                    window.open(a.getAttribute('href'), "_blank");

                });

            });

        }

    }

</script>

