import store from './store'
import localforage from 'localforage';
import axios from 'axios'
import app from "./app";


export default class ResourceManager {

    constructor(store) {

        this.availableResources = []
        this.store = store

    }

    /**
     *
     * @param name
     * @param url
     * @param options
     */
    setupResource(name, url, options) {

        this.availableResources.push({
            name,
            url,
            options,
        })

    }


    /**
     *
     * @param name
     * @returns {*}
     */
    getResourceByName(name) {

        return this.availableResources.filter((r) => r.name == name)[0];

    }

    /**
     *
     * @param name
     * @returns {Promise}
     */
    loadResourceLocally(name) {

        return new Promise((resolve, reject) => {

            if (!this.getResourceByName(name)) {
                reject()
                return;
            }

            localforage.getItem('resource.' + name)
                .then((data) => {
                    this.setResource(name, data ? data.data : undefined)
                    resolve()
                })
                .catch(reject)

        })

    }

    /**
     *
     * @returns {Promise.<*>}
     */
    loadResourcesLocally() {

        let promises = []

        this.availableResources.forEach((res) => {
            promises.push(this.loadResourceLocally(res.name))
        })

        return Promise.all(promises)

    }

    /**
     *
     * @param name
     * @returns {Promise}
     */
    loadResourceRemote(name) {

        return new Promise((resolve, reject) => {

            let resource = this.getResourceByName(name)

            if (!resource) {
                reject()
                return;
            }

            axios.get(resource.url, {...resource.options}).then((response) => {
                this.setResource(name, response.data, true)
                resolve()
            }).catch(reject)

        })

    }

    /**
     *
     * @param name
     * @returns {Promise}
     */
    loadResourcesRemote() {


        let promises = []

        this.availableResources.forEach((res) => {
            promises.push(this.loadResourceRemote(res.name))
        })

        return Promise.all(promises)

    }


    /**
     *
     * @param key
     * @param data
     * @param isFreshResource
     */
    setResource(key, data, isFreshResource = false) {

        let resources = this.store.getters.resources;

        if(data === undefined){

            resources[key] = undefined;

        }
        else {

            let res = resources[key] || {};

            res.data = data;

            if(isFreshResource || !res.time){
                res.time = new Date();
            }

            resources[key] = res;

        }


        this.store.commit('setResources', resources)

        if(isFreshResource)
            this.saveResources()

    }


    /**
     *
     * @param key
     * @returns {Promise}
     */
    getResource(key) {

        return this.store.getters.resources[key]

    }

    /**
     *
     * @param name
     * @returns {*}
     */
    isResourceLoaded(name) {

        return this.store.getters.resources[name];

    }



    /**
     *
     */
    saveResources() {

        let promises = [];

        this.availableResources.forEach((res) => {

            promises.push(new Promise((resolve, reject) => {

                if( this.store.getters.resources[res.name] )
                    localforage.setItem('resource.' + res.name, JSON.parse(JSON.stringify(this.store.getters.resources[res.name])))
                        .then(resolve)
                        .catch(reject)

            }))

        })

        return Promise.all(promises)

    }


}