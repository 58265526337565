<template>

    <div class="background">

        <slot></slot>

    </div>

</template>

<style lang="scss" scoped>

    @import "../scss/includes.scss";

    .background {


        width: 100%;
        height: 100%;
        background: color(1, 'dark-red');
        background-image: url('../assets/img/background.png');
        background-size: cover;
        background-position: center top;

    }

</style>