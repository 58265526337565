<template>
    <div class="button" :class="{'has-icon': icon, 'icon-right': iconRight, 'glass-style': glassStyle, 'expand': fullWidth, ghost: ghost}">
        <button class="ripple">
            <icon v-if='icon' class='icon' :icon="icon"></icon>
            <div class="cont">
                <div class="loading" v-if="loading">
                    <spiner></spiner>
                    <slot></slot>
                </div>
                <slot v-else></slot>
            </div>
        </button>
    </div>
</template>

<script>


    export default {

        props: {
            icon: {
                required: false,
            },
            iconRight: {
                default: false,
            },
            glassStyle: {
                default: true,
            },
            fullWidth:{
                default: false,
            },
            loading:{
                default: false,
            },
            ghost:{
                default: false,
            }

        },


        methods: {}

    }


</script>

<style lang="scss" scoped>

    @import "../scss/includes.scss";

    div.button {

        &.icon-right button {
            flex-direction: row-reverse;
        }

        &.glass-style button {
            @include glass-style();
        }

        &.icon-right .icon {
            margin-right: 0px;
            margin-left: 5px;
        }

        &.disabled button{
            pointer-events: none;
            opacity: 0.4;
        }

        &.expand button{
            width: 100%;

        }

        &.ghost button{
            background: none;

        }

    }

    button {

        background: none;
        box-shadow: none;
        border: none;
        transition: none;

        height: $form-one-line-input-height;
        border-radius: $default-border-radius-small;
        color: white;
        font-family: inherit;
        padding: 0 22px;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
            font-size: 18px;
            margin-right: 5px;
        }

        .cont {
            text-transform: uppercase;
            font-weight: 600;
            font-size: 0.85em;
        }

        .loading{
            display: flex;
            justify-content: center;
            align-items: center;
            span{
                margin-right: 5px;
            }
        }


    }

</style>
