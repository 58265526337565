export default {

    "views.language.title": "Выбор языка",
    "views.language.confirm": "Подтверди",

    // Intro
    "views.intro.1.title": "Добро пожаловать",
    "views.intro.2.title": "Борьба с торговлей людьми",
    "views.intro.3.title": "Узнайте больше о торговле людьми",
    "views.intro.4.title": "Заполните опрос",
    "views.intro.1.description": "ASTRA - неправительственная организация, занимающаяся искоренением всех форм торговли людьми и эксплуатации",
    "views.intro.2.description": "Борьба с торговлей людьми должна быть комплексной, что предполагает действия в области предотвращения, защиты и поддержки жертв, а также уголовное преследования виновных.",
    "views.intro.3.description": "Данное приложение даст Вам основную информацию о торговле людьми и условиях трудоустройства в Сербии и за рубежом, о том, как защитить себя, а также полезные контакты в стране и за рубежом.",
    "views.intro.4.description": "Чтобы действовать максимально эффективно, ASTRA проводит добровольный опрос. Присоединяйтесь к нам в борьбе с торговлей людьми",
    "views.intro.button.next": "Дальше",
    "views.intro.button.survey": "Опрос",
    "views.intro.button.survey.skip": "Пропустить",
    "views.intro.button.start.survey": "Начать опрос",
    "views.intro.button.back.survey": "Назад",
    "views.intro.button.start.game": "Начать игру",

    // SOS
    "views.sos.description": "Если Вам необходима дополнительная профилактическая информация, у Вас есть дилеммы, Вы узнаете ту или иную форму торговли людьми в Вашей среде или Вам нужна помощь и поддержка, Вы можете связаться с нами.",
    "views.sos.report-problem.title": "Сообщить о проблеме",
    "views.sos.report-problem.message": "Свяжитесь с нами посредством формы",
    "views.sos.phone.1.message": "Доступно 24/7",
    "views.sos.phone.2.message": "Доступно 24/7 (бесплато на территории Сербии)",
    "views.sos.fb.title": "Facebook/Messenger",
    "views.sos.fb.message": "Официальная страница facebook",
    "views.sos.mail.title": "E-Mail",
    "views.sos.mail.message": "Свяжитесь с нами посредством E-mail-a (sos@astra.rs)",

    //Country residence
    "views.eol.country.title": "Выбор страны",
    "views.eol.country.description": "Если Вы подумываете о поиске работы за рубежом, мы подготовили для Вас полезную информацию об условиях трудоустройства в некоторых странах Европы. ",

    //Exploitation
    "views.exploitation.description":"Соберите информацию и узнайте о своих правах, прежде чем принимать решение о приеме на работу.",
    "views.exploitation.general.title": "Полезная информация",
    "views.exploitation.general.desc": "Полезная информация о трудовой эксплуатации",
    "views.exploitation.domestic.title": "Если Вы находитесь за границей",
    "views.exploitation.domestic.desc": "Для граждан Сербии",
    "views.exploitation.foreign.title": "Если Вы иностранец в Сербии",
    "views.exploitation.foreign.desc": "Для иностранных граждан в Сербии",

    // Home
    "views.home.quiz.title": "Викторина",
    "views.home.quiz.description": "Узнайте больше о торговле людьми с помощью викторины",
    "views.home.quiz.play": "Начать викторину",
    "views.home.news-activities-title": "Новости и мероприятия",
    "views.home.more": "Более...",


    // Astra services
    "views.astra-services.title": "Услуги, предоставляемые Astra",
    "views.astra-services.desc-short": "Узнайте больше об услугах",
    "views.astra-services.desc": "На данной странице Вы можете найти информацию об услугах, которые предоставляет Astra.",
    // Quiz
    "views.quiz.title": "Викторина",
    "views.quiz.description": "Узнайте больше о торговле людьми с помощью интерактивной викторины. Выберите одну из викторин.",
    "views.quiz.play":"Начать викторину",
    'views.quiz.stats.question': 'Вопрос',
    'views.quiz.stats.score': 'Баллы',
    'views.quiz.stats.time': 'Время',
    'views.quiz.end.title': 'Конец игры',
    'views.quiz.end.description': 'Спасибо за участие в викторине.',
    'views.quiz.end.correct-answers': 'Правильные ответы',
    'views.quiz.end.incorrect-answers': 'Неправильные ответы',
    'views.quiz.end.question-summary': 'Вопросы',
    'views.quiz.timeout': 'Ваше время истекло',
    'views.quiz.action.next': 'Дальше',
    'views.quiz.action.ok': 'В порядке',
    'views.quiz.action.skip': 'Пропустить',
    'views.quiz.action.confirm': 'Подтверди',
    'views.quiz.info': 'Информация',
    'views.quiz.right': 'Вы правы.',
    'views.quiz.wrong': 'К сожалению, Вы ошиблись. ',

    'views.back.exit.title': 'Вы уверены?',
    'views.back.exit.desc': 'Вы уверены, что хотите выйти из экрана? Ваши данные будут потеряны (викторина, опрос).',

    // Anti trafficking
    "views.anti-trafficking.title": "Борьба с торговлей людьми",

    // Report problem
    "views.report-problem.intro.description": "Если Вы чувствуете себя неуверенно, напуганы или не хотите делиться с нами своими личными данными, Вы также можете написать нам анонимно. ",
    "views.report-problem.form.d.description": "Уважаемый/ая, спасибо за Ваше доверие. Пожалуйста, оставьте свои контактные данные в заявке и максимально подробно опишите ситуацию, о которой Вы сообщаете. Ожидайте, что мы свяжемся с Вами в кратчайшем сроке. ",
    "views.report-problem.form.a.description": "Уважаемый/ая, спасибо за Ваше доверие. Учитывая специфику анонимных сообщений, мы сможем принять меры в зависимости от объема информации, с которой Вы с нами делитесь. Пожалуйста, опишите проблему как можно подробнее и, если возможно, как связаться с человеком, который потенциально рискует стать жертвой торговли людьми.",
    "views.report-problem.action.data": "Сообщение с личными данными",
    "views.report-problem.action.anonymous": "Анонимное сообщение",
    "views.report-problem.end.placeholder.title": "Спасибо Вам!",
    "views.report-problem.end.placeholder.message": "Ваше сообщение отправлено.",
    "views.report-problem.end.placeholder.back": "Назад",
    'views.report-problem.form.label.name': "Имя и фамилия",
    'views.report-problem.form.label.phone': "Контактный телефон",
    'views.report-problem.form.label.mail': "E-mail",
    'views.report-problem.form.label.title': "Заголовок",
    'views.report-problem.form.label.message': "Сообщение",
    'views.report-problem.form.submit': "Отправить",
    'views.report-problem.validation.title': 'Пожалуйста, проверьте поля',
    'views.report-problem.validation.desc': 'Некоторые поля пусты или имеют неправильный формат. Пожалуйста, проверьте все поля.',
    'views.report-problem.client.title': 'Ошибка клиента',
    'views.report-problem.client.desc': 'Нет сети',
    'views.report-problem.server.title': 'Ошибка сервера',
    'views.report-problem.server.desc': 'Произошла непредвиденная ошибка',

    // Survey
    "views.survey.title": "Опрос",
    "views.survey.description": "Для того, чтобы лучше понять явление трудовой эксплуатации и спланировать нашу будущую деятельность, Ваши знания и опыт, которыми Вы можете поделиться с нами, ответив на несколько вопросов в анонимном опросе, будут иметь для нас большое значение.",
    "views.survey.start": "Начать опрос",
    'views.survey.exit.title': 'Вы уверены?',
    'views.survey.exit.desc': 'Вы уверены, что хотите выйти из опроса?',
    "views.survey-welcome.title": "Добро пожаловать",
    "views.survey-welcome.placeholder.title": "Начать опрос",
    "views.survey.placeholder.content": "Maecenas sit amet sollicitudin dolor, quis fringilla tortor, quis fringilla tortor.",
    "views.survey.placeholder.button.content": "Продолжить",
    "views.survey.button.next": "Дальше",
    "views.survey.button.previous": "Назад",
    "views.survey.end-screen.title": "Спасибо Вам!",
    "views.survey.end-screen.content": "Эти данные будут использоваться в целях планирования мероприятий по борьбе с трудовой эксплуатацией и развитию услуг, предоставляемых (потенциальным) жертвам торговли людьми.",
    "views.survey.end-screen.button.content": "Назад",

    // Post
    'views.post.title': 'Новости',

    // Resource placeholder
    'placeholder.network.title': 'Ошибка в сети',
    'placeholder.network.message': 'Нет данных. Пожалуйста, проверьте подключение к интернету и повторите попытку.',
    'placeholder.network.action': 'Попробуйте снова',

    // Menu
    "menu.home": "Начало",
    "menu.anti-trafficking": "Торговля людьми",
    "menu.eol": "Трудовая эксплуатация",
    "menu.sos": "SOS",
    "menu.quiz": "Викторина",
    "menu.survey": "Опрос",
    "menu.report-problem": "Сообщить о проблеме",
    "menu.exit": "Выход",

    // Misc
    "cancel": "Отказаться",
    "confirm": "Подтверди",
    "ok": "В порядке",
    "yes": "Да",
    "no": "Нет",
    "translate": "Переведи",
    "available-translations": "Доступные переводы",
    "auto-translate": "Автоматический перевод",
    "share-news": "Поделитесь этой новостью",
    "share-app": "Поделитесь приложением",
    "share.try-astra-app": "Попробуйте приложение Astrа",
    "music": "Музыка",
    "privacy-policy": "Политика конфиденциальности",

    "dialog.survey.title": "Новый опрос!",
    "dialog.survey.message": "В приложении доступен новый опрос. Помогите нам в миссии. Заполните опрос!",
    "dialog.survey.later": "Позже",
    "dialog.survey.go": "Начать опрос!",

    "dialog.share-app.title": "Поделитесь приложением Astra с другими",

    // Level Stats
    "level-stats.current-level": "Ваш текущий уровень",
    
    "level-stats.level.1.title": "Искра надежды",
    "level-stats.level.1.desc": "На данный момент ты \"Искра надежды\"! Продолжайте свой путь к повышению осведомленности о торговле людьми с помощью нашей образовательной платформы.",
    "level-stats.level.2.title": "Путешественник Справедливости",
    "level-stats.level.2.desc": "Ваша поддержка имеет решающее значение! Вы внесли свой вклад своим участием и повышением осведомленности. Продолжайте свой прогресс!",
    "level-stats.level.3.title": "Голос Свободы",
    "level-stats.level.3.desc": "Как текущий \"Голос Свободы\", Ваша приверженность борьбе с торговлей людьми вдохновляет других. Продолжайте устанавливать стандарты для перемен!",

    "level-stats.upgraded.title": "Поздравляем!",
    "level-stats.upgraded.desc": "Вы перешли на следующий уровен!",

    "level-stats.more": "Как мне двигаться вперед?",

    // Levels
    "views.levels.title": "Уровни",
    "views.levels.page-title": "Проявите активность и внесите свой вклад в общую борьбу с торговлей людьми.",
    "views.levels.page-desc": "Выполняйте задания, чтобы стать настоящим борцом с торговлей людьми. Получайте информацию, участвуйте в викторинах и опросах и делитесь приложением с друзьями.",
    
    "views.levels.quiz.title": "Игра-викторина",
    "views.levels.quiz.desc": "Примите участие в викторине, проверьте свои знания о торговле людьми и выиграйте [n] баллов.",

    "views.levels.share.title": "Поделитесь приложением",
    "views.levels.share.desc": "Поделитесь приложением с друзьями и выиграйте [n] баллов.",

   "views.levels.survey.title": "Опрос",
    "views.levels.survey.desc": "Заполните опрос и выиграйте [n] баллов.",

}
