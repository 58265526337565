<template>
    <div class="input" :class="{'has-icon': icon}">
        <icon v-if='icon' class='icon' :icon="icon"></icon>
        <icon class='icon-dropdown' icon="chevron-down"></icon>
        <select :value="modelValue" @input="onInput">
            <option v-for="option in options" :value="option.key">{{option.label}}</option>
        </select>
    </div>
</template>

<script>



    export default {

        props: {
            options: {
                required: true,
                type: Array
            },
            modelValue: {
                required: false,
            },
            icon: {
                required: false,
            }
        },

        emits: ['update:modelValue'],

        data() {

            return {
            }

        },

        methods: {

            onInput(evt){

                this.$emit('update:modelValue', evt.target.value)

            }

        },

        mounted(){
        }




    }


</script>

<style lang="scss" scoped >

    @import "../scss/includes.scss";

    .input{
        width: 100%;
        position: relative;

        &.has-icon select{
            padding-left:  $form-one-line-input-height * 0.9;
        }

    }

    .icon-dropdown,
    .icon{
        pointer-events: none;
        position: absolute;
        height: $form-one-line-input-height !important;
        width:$form-one-line-input-height !important;
        display: flex;
        align-items:center;
        justify-content: center;
        font-size: 18px;
    }

    .icon-dropdown{
        right: 0;
    }

    select {

            @include one-line-form-element-glass();

        -moz-appearance: none;
        -webkit-appearance: none;
        padding-right:  $form-one-line-input-height * 0.9;

        option {
            background: white;
            color: black;
        }
    }

</style>