<template>
    <div class="section-title">
        <icon :icon="icon"></icon>
        <span class="title">
            <slot></slot>
        </span>
    </div>
</template>

<script>

    export default {

        props: {
            icon: {
                required: true
            }
        },

    }


</script>

<style lang="scss" scoped >

    @import "../scss/includes.scss";

    .icon{
        font-size: 18px;
        height: 18px;
        margin-right: 5px;
        color: white;
    }

    .title{
    }

    .section-title{
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(255,255,255,0.15);
        padding-bottom: 10px;
        margin-bottom: 20px;
    }


</style>