<template>
    <background>

            <div class="flex-layout">

                <view-header :title="lang('menu.eol')"></view-header>
                <div class="view p-lr page-eol">
                    <div class="content">

                        <div class="page-intro">

                            <h1>{{ lang('views.exploitation.general.title') }}</h1>
                            <p>{{ lang('views.exploitation.general.desc') }}</p>

                        </div>

                        <post-menu :posts="posts" category="eol"></post-menu>

                    </div>
                </div>

            </div>

    </background>
</template>

<script>


import settings from "../settings";

export default {

    computed: {

        posts() {

            let resource = this.getResource('eol');

            return resource.data.attributes.EOLGiPosts.posts.data.map( (q) => {
                return q
            } ).filter(p => p)

        },

    },

}


</script>

