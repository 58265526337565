<template>
    <div id="main" :class="{'transitions-active': $store.state.transitionsActive}">
        <router-view v-slot="{ Component, route }" >

            <transition :name="route.meta.transitionName">
                <keep-alive :include="keepAliveInclude">
                    <component :is="Component" :key="$route.fullPath"/>
                </keep-alive>
            </transition>

        </router-view>
        <drawer-menu></drawer-menu>
        <div id="editor-refresh-btn" @click="editorRefresh" :class="{loading: resourceReload, active: editorActive}">
            <icon icon="zap"></icon>
           <span>Downloading data...</span>
        </div>
    </div>
</template>

<script>

    import app from "./app";
    import helpers from "./helpers";

    export default {
        data(){
            return {
                keepAliveInclude: [],
                editorTimes: 0,
                editorActive: false,
                resourceReload: false,
            }
        },
        computed: {
            currentUserLevel(){
                return this.$store.getters.currentLevel;
            }
        },
        watch: {

            editorActive(newValue, oldValue){

                app.setTestingFCMSubscription( newValue )

            },

            currentUserLevel(newValue, oldValue){

                helpers.confirmDialog({
                    title: this.lang('level-stats.upgraded.title'),
                    message: this.lang('level-stats.upgraded.desc') + " " + this.lang("level-stats.level."+newValue+".desc"),
                    buttons: [{
                        label: this.lang('ok'),
                    },]
                });

            }

        },
        methods: {

            /**
             *
             */
            editorRefresh(){

                if(this.resourceReload)
                    return;

                this.editorTimes++;

                if(this.editorTimes >= 7 || (this.editorActive && this.editorTimes == 1)){

                    this.resourceReload = true;

                    app.resourceManager.loadResourcesRemote().then( () => {
                        this.editorTimes = 0;
                        this.resourceReload = false;
                        this.editorActive = true;
                    } );

                }

            }
        }
    }

</script>


<style lang="scss">

    #editor-refresh-btn{
        position: fixed;
        left: 0;
        bottom: 0;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        background: black;
        color: white;

        span{
            display: none;
        }

        &.active{
            opacity: 1;
        }

        &:active{
            background: white;
            color: black;
        }

        &.loading{
            pointer-events: none;
            .icon{
                display: none;
            }
            width: 100%;
            opacity: 1;
            span{
                display: inline-block;
            }
        }

    }

</style>
