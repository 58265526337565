<template>
    <background>

        <div class="flex-layout">

            <view-header :title="lang('menu.anti-trafficking')"></view-header>
            <div class="view p-lr page-eol">
                <resource-placeholder :resource="'antiTrafficking'">
                    <div class="content">

                        <div class="page-intro"></div>

                        <post-menu :posts="posts" category="at"></post-menu>

                    </div>
                </resource-placeholder>
            </div>

        </div>

    </background>
</template>

<script>


    import settings from "../settings";

    export default {

        computed: {

            posts() {

                let resource = this.getResource('antiTrafficking');

                return resource.data.attributes.posts.posts.data

            },

        },


    }


</script>


<style lang="scss" scoped>


    @import '../scss/includes.scss';




</style>
