<template>
    <div class="square-button" :class="[ 'size-' + size, {solid: solid}]">
        <button>
            <ButtonSvg />
            <icon v-if='icon' class='icon' :icon="icon"></icon>
        </button>
        <div class="cont" v-if="$slots.default">
            <slot></slot>
        </div>
    </div>
</template>

<script>

    import ButtonSvg from "../assets/img/button.svg?inline";

    export default {

        components: {
            ButtonSvg
        },

        props: {
            icon: {
                required: false,
            },
            size: {
                required: false,
                default: 'normal',
            },
            solid: {
                required: false,
                default: false
            }
        },


        methods: {}

    }


</script>

<style lang="scss"  >

    @import "../scss/includes.scss";


    .square-button{

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;


        button {
            @extend %ripple;
            position: relative;
            border-radius: 30%;

            > svg{
                width: 100%;
                height: 100%;
                position: absolute;

                path{
                    @include glass-style-svg();
                    overflow: hidden;

                }
            }


            background: none;
            border: none;
            outline: none;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;

        }

        
        &.solid button > svg path {
            stroke: none;
        }

        &.size-normal{

            button{
                width: 60px;
                height: 60px;
                .icon{
                    font-size: 25px;
                }
                > svg path{
                    stroke-width: 80mm;
                }
            }

            .cont{
            }

        }

        &.size-small{

            button{
                width: 50px;
                height: 50px;
                .icon{
                    font-size: 20px;
                }
                > svg path{
                    stroke-width: 80mm;
                }
            }

            .cont{
            }

        }


        .cont{
            margin-top: 10px;
            text-align: center;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 0.71em;
            line-height: 1.4em;
        }

    }



</style>
