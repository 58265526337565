export default {



    appUrl: "https://astra.rs/app",
    wpWebsiteUrl: "https://astra.rs/",

    // Pages
    newsPageUrl: 'https://www.astra.rs/category/astra_vesti/',

    fbPageUrl: 'https://www.facebook.com/astraantitrafficking/',
    ytPageUrl: 'https://www.youtube.com/user/AstraAntiTrafficking',
    instaPageUrl: 'https://www.instagram.com/astra_antitrafficking',
    twPageUrl: 'https://twitter.com/astra_beograd',

    serverBaseUri: "https://astra-api.modem.rs",
    // serverBaseUri: "http://localhost:1339",

    // Debug
    sendStatsToServer: process.env.NODE_ENV !== 'development',

    // Total Levels
    //  After adding new level, you should add new level in languages to 
    //  Example
    //  "level-stats.level.[number].title" : "Iskra Nade",
    totalLevels: 3,
    levelColors: [ '#f3ae45', '#45abf2', '#59c32f' ],

    // Idea is: quiz 90 + share 60 + survey 50 = 200 points
    // but in order to reach last level user must share at least once

    // Share points
    oneSharePoints: 20,
    sharesRecommended: 3,

    // Survey points
    surveyPoints: 50,

    // Quiz settings
    questionPoint: 5,
    questionTimeout: 25,
    allowResultDowngrade: false,


}
