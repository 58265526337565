<template>


    <div>

        <div class="video-container" v-if="post.Video" >
            <iframe :src="ytVideo(post.Video)"></iframe>
        </div>

        <template v-for="content in post.content">

            <post-content v-if="content.__component == 'components.content-editor'" :content="markdownToHtml(content.text)" />

            <template v-if="content.__component == 'components.links'" >

                <div class="post-content">
                    <h3>{{content.title}}</h3>
                </div>
                <ul class="post-content-contact-item">
                    <li v-for="item in content.links">

                        <a :href="item.url" target="_blank">

                            <c-square-button size='small' :icon="itemIcon(item)"></c-square-button>
                            <div class="info">
                                <label>{{item.label}}</label>
                                <span>{{item.description}}</span>
                            </div>
                        </a>

                    </li>
                </ul>

            </template>
            <template v-if="content.__component == 'components.submenu'" >

               <post-menu :posts="content.posts.data"></post-menu>

            </template>

        </template>

    </div>


</template>

<script>
import helpers from '../helpers';


    export default {

        props: {
            post: {
                default: '',
            }
        },

        methods: {

            itemIcon( item ){

                if(item.icon == 'map')
                    return 'map-pin';

                return item.icon

            },

            ytVideo(url){

                return "https://www.youtube.com/embed/" + helpers.parseYoutubeVideoURL(url)

            }


        },

    }

</script>

